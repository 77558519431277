import {
    APP_INITIALIZER,
    ApplicationConfig,
    ErrorHandler,
    LOCALE_ID,
    importProvidersFrom,
    provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { provideRouter, Router, withRouterConfig } from '@angular/router';
import { appRoutes } from './app.routes';
import { AuthGuard } from '@web/app/modules/auth/guards/auth.guard';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { GlobalErrorInterceptor } from '@web/core/interceptors/error.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserModule } from '@angular/platform-browser';
import { EnumTranslatePipe } from '@web/shared/pipes/enum-translate.pipe';
import { PluralTranslatePipe } from '@web/shared/pipes/plural-translate.pipe';
import { environment } from '@web/envs/environment';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './core/store/storage-meta';
import { provideAngularQuery, QueryClient } from '@tanstack/angular-query-experimental';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LocalStorageKey } from './shared/enums/local-storage-key';
import * as Sentry from "@sentry/angular";

const core_providers = [
    provideExperimentalZonelessChangeDetection(),
    {
        provide: APP_INITIALIZER,
        useFactory: () => (): void => {},
        deps: [Sentry.TraceService],
        multi: true,
    },
    importProvidersFrom(
        BrowserModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: [environment.API_URL.split('://')[1]],
                disallowedRoutes: [],
            },
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            defaultLanguage: localStorage.getItem('lang') || 'en',
        }),
        StoreModule.forRoot(reducers, {
            metaReducers: metaReducers,
        }),
        StoreDevtoolsModule.instrument({})
    ),
];

const guards_providers = [AuthGuard];

const errorHandling_providers = [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
];

const interceptors_providers = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: GlobalErrorInterceptor,
        multi: true,
    },
];

const other_providers = [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideAngularQuery(new QueryClient()),
    provideRouter(appRoutes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),

    EnumTranslatePipe,
    PluralTranslatePipe,
    DatePipe,
];

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(appRoutes),
        ...core_providers,
        ...guards_providers,
        ...other_providers,
        ...errorHandling_providers,
        ...interceptors_providers,

        provideAnimations(),
    ],
};

registerLocaleData(localeFr);

export function tokenGetter(): string | null {
    const userId = localStorage.getItem(LocalStorageKey.USER_ID);
    return localStorage.getItem(`${userId}_${LocalStorageKey.JWT_TOKEN}`) ?? null;
}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cacheBuster=' + new Date().getTime());
}
