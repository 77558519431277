import { ErrorDefinition, ModuleName } from '../types';

const errorFromModule = ModuleName.THREADS;

export enum ThreadsError {
    THREAD_NOT_FOUND = 'THREAD_NOT_FOUND',
}

export const THREADS_ERRORS: ErrorDefinition<ThreadsError> = {
    [ThreadsError.THREAD_NOT_FOUND]: {
        errorFromModule,
        message: 'Thread not found',
        statusCode: 404,
    },
};
