import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'applyPure',
    pure: true,
    standalone: true,
})
export class ApplyPurePipe implements PipeTransform {
    transform(fn: Function, ...args: any[]): any {
        return fn(...args);
    }
}

@Pipe({
    name: 'apply',
    pure: false,
    standalone: true,
})
export class ApplyPipe implements PipeTransform {
    transform(value: any, fn: Function): any {
        return fn(value);
    }
}

@Pipe({
    name: 'applySelfPure',
    pure: true,
    standalone: true,
})
export class ApplySelfPurePipe implements PipeTransform {
    transform(value: any, fn: string, ...args: any[]): any {
        return value?.[fn]?.(...args);
    }
}

@Pipe({
    name: 'applySelf',
    pure: false,
    standalone: true,
})
export class ApplySelfPipe implements PipeTransform {
    transform(value: any, fn: string, ...args: any[]): any {
        return value?.[fn]?.(...args);
    }
}
