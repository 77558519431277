import { ErrorDefinition, ModuleName } from '../types';

const errorFromModule = ModuleName.USERS;

export enum UsersError {
    USER_NOT_FOUND = 'USER_NOT_FOUND',
}

export const USERS_ERRORS: ErrorDefinition<UsersError> = {
    [UsersError.USER_NOT_FOUND]: {
        errorFromModule,
        message: 'User not found',
        statusCode: 404,
    },
};
