import { ChangeDetectionStrategy, Component, effect, EnvironmentInjector, inject, OnInit, runInInjectionContext, viewChild } from '@angular/core';
import {
  BrnAlertDialogContentDirective,
  BrnAlertDialogTriggerDirective
} from '@spartan-ng/ui-alertdialog-brain';
import {
  HlmAlertDialogActionButtonDirective,
  HlmAlertDialogCancelButtonDirective,
  HlmAlertDialogComponent,
  HlmAlertDialogContentComponent,
  HlmAlertDialogDescriptionDirective,
  HlmAlertDialogFooterComponent,
  HlmAlertDialogHeaderComponent,
  HlmAlertDialogOverlayDirective,
  HlmAlertDialogTitleDirective,
  HlmButtonDirective,
} from '@recapp/ui';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomDialogService } from '@web/app/shared/services/custom-dialog.service';

export interface AlertDialogData {
  title: string;
  description: string;
  actions: {
    primary: {
      text: string;
      onClick: () => void;
    };
    secondary: {
      text: string;
      onClick: () => void;
    };
  };
}

@Component({
  selector: 'spartan-alert-dialog-preview',
  standalone: true,
  imports: [
    BrnAlertDialogContentDirective,
    HlmAlertDialogComponent,
    HlmAlertDialogOverlayDirective,
    HlmAlertDialogTitleDirective,
    HlmAlertDialogDescriptionDirective,
    HlmAlertDialogContentComponent,
    HlmButtonDirective,
    BrnAlertDialogTriggerDirective,
  ],
  templateUrl: './alert-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent implements OnInit {
  readonly dialog = viewChild(HlmAlertDialogComponent);
  readonly data = inject<AlertDialogData>(MAT_DIALOG_DATA);
  readonly dialogService = inject(CustomDialogService);
  private environmentInjector = inject(EnvironmentInjector);


  ngOnInit(): void {
    setTimeout(() => {
      document.getElementById('alert-trigger')?.click();

      runInInjectionContext(this.environmentInjector, () => {
        effect(() => {
        if(this.dialog()?.state() === 'closed') {
          console.log('Dialog closed');
          this.dialogService.closeAll();
        }
      });
      });
    });
  }

}