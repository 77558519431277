import { inject, Injectable } from '@angular/core';
import { EmailDTO } from '@recapp/dto';
import { ApiResult, SuccessResult } from '@recapp/shared-types';
import { TrpcService } from '@web/app/core/services/trpc.service';
import { Email } from '@web/app/modules/inbox/models/email.model';
import { Thread } from '@web/app/modules/inbox/models/thread.model';

@Injectable({
    providedIn: 'root',
})
export class ThreadsService {
    private readonly _trpcService = inject(TrpcService);

    getThread(threadId: string): Promise<ApiResult<Thread>> {
        return this._trpcService.api.threads.getThread(this._trpcService.connection, threadId).then((res) => ({
            ...res,
            data: Thread.fromDto(res.data),
        }));
    }

    updateThreadReadState({ threadsIds, isRead }: { threadsIds: string[]; isRead: boolean }): Promise<ApiResult<SuccessResult>> {
        return this._trpcService.api.threads.read_state
            .updateReadState(this._trpcService.connection, { isRead, threadsIds })
            .then((res) => new ApiResult(res.data));
    }

    trashThreads(threadsIds: string[]): Promise<ApiResult<SuccessResult>> {
        return this._trpcService.api.threads
            .trash(this._trpcService.connection, { threadsIds: threadsIds.map((t) => t.toString()) })
            .then((res) => new ApiResult(res.data));
    }

    deleteThreads(threadsIds: string[]): Promise<ApiResult<SuccessResult>> {
        return this._trpcService.api.threads
            .$delete(this._trpcService.connection, { threadsIds: threadsIds.map((t) => t.toString()) })
            .then((res) => new ApiResult(res.data));
    }

    updateThreadLabels({
        threadIds,
        labelsToAdd,
        labelsToRemove,
    }: {
        threadIds: string[];
        labelsToAdd: string[];
        labelsToRemove: string[];
    }): Promise<ApiResult<SuccessResult>> {
        return this._trpcService.api.threads.labels
            .update(this._trpcService.connection, { threadIds, labelsToAdd, labelsToRemove })
            .then((res) => new ApiResult(res.data));
    }

    search(text: string): Promise<ApiResult<Email[]>> {
        return this._trpcService.api.threads.search.execute(this._trpcService.connection, { options: {}, searchTerm: text  }).then((res) => new ApiResult(res.data.map(Email.fromDto)));
    }
}
