import { Routes } from '@angular/router';

import { AuthSuccessComponent } from '../views/auth-success/auth-success.component';
import { LoginComponent } from '../views/login/login.component';

export const AUTH_ROUTES: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'login',
            },
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'success',
                component: AuthSuccessComponent,
            },
        ],
    },
];
