/**
 * @packageDocumentation
 * @module api.functional.api.boards
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Primitive, Resolved } from "typia";
import typia from "typia";

import type { BoardDTO } from "@recapp/dto";
import type { ApiResult } from "@recapp/shared-types";

/**
 * @controller BoardController.getBoard
 * @path GET /api/boards
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getBoard(
  connection: IConnection,
): Promise<getBoard.Output> {
  return !!connection.simulate
    ? getBoard.simulate(connection)
    : PlainFetcher.fetch(connection, {
        ...getBoard.METADATA,
        template: getBoard.METADATA.path,
        path: getBoard.path(),
      });
}
export namespace getBoard {
  export type Output = Primitive<ApiResult<BoardDTO, never>>;

  export const METADATA = {
    method: "GET",
    path: "/api/boards",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = () => "/api/boards";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<BoardDTO, never>>> =>
    typia.random<Primitive<ApiResult<BoardDTO, never>>>(g);
  export const simulate = (connection: IConnection): Output => {
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
