import { Route } from '@angular/router';

export const INBOX_ROUTES: Route[] = [
    {
        path: '',
        pathMatch: 'full',
        loadComponent() {
            return import('./inbox.component').then((m) => m.InboxComponent);
        },
        children: [
            {
                path: '',
                loadComponent() {
                    return import('./inbox-list-view/inbox-list-view.component').then((m) => m.InboxListViewComponent);
                },
            },
        ],
    },
];
