import { computed, inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { injectInfiniteQuery, injectQuery, injectQueryClient } from '@tanstack/angular-query-experimental';
import { UserState, selectSelectedUserId } from '../../users/store/user.reducer';
import { InboxContext } from './inbox.context';
import { InboxService } from '../services/inbox.service';
import { Thread } from '../models/thread.model';

@Injectable({
    providedIn: 'root',
})
export class InboxQueries {
    private readonly _store = inject(Store<UserState>);
    private readonly _inboxContext = inject(InboxContext);
    private readonly _inboxService = inject(InboxService);

    private readonly pageSize = 50;

    readonly queryClient = injectQueryClient();
    readonly currentUserId = this._store.selectSignal(selectSelectedUserId);

    readonly inboxThreads = computed(() => this.getThreadsPaginatedQuery.data()?.pages.flatMap((p) => p.data) ?? []);

    readonly getThreadsPaginatedQuery = injectInfiniteQuery(() => ({
        queryKey: [this.currentUserId(), ...this._inboxContext.currentSelectedLabels()],
        queryFn: ({ pageParam }) =>
            this._inboxService.getInboxThreadsPaginated({
                userId: this.currentUserId()!,
                pageNumber: pageParam.pageNumber,
                pageSize: this._inboxContext.isUnreadTabSelected() ? 1000 : this.pageSize,
                labelsNames: this._inboxContext.currentSelectedLabels(),
            }),
        refetchInterval: 10000,
        initialPageParam: { pageNumber: 0 },
        getNextPageParam: (lastPage) => {
            return lastPage?.metadata?.hasMore ? { pageNumber: lastPage.metadata.pageNumber + 1 } : undefined;
        },
    }));

    readonly getUnreadThreadsCountQuery = injectQuery(() => ({
        queryKey: [this.currentUserId(), 'unread-threads-count'],
        queryFn: () => this._inboxService.getUnreadThreadsCount(this.currentUserId()!),
        enabled: !!this.currentUserId()!,
        keepPreviousData: false,
        refetchInterval: 10000,
    }));

    updateThread(thread: Thread): void {
        this.queryClient.setQueryData(
            [this._inboxContext.currentUserId(), ...this._inboxContext.currentSelectedLabels()],
            (oldData: any) => {
                if (!oldData || !oldData.pages) {
                    return oldData;
                }
                return {
                    ...oldData,
                    pages: oldData.pages.map((page: any) => {
                        return {
                            ...page,
                            data: page.data.map((t: Thread) => (t.id === thread.id ? thread : t)),
                        };
                    }),
                };
            }
        );
    }
}
