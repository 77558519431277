import { Component, computed, input } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { ClassValue } from 'clsx';

@Component({
    selector: 'hlm-menu-separator',
    standalone: true,
    template: ``,
    host: {
        '[class]': '_computedClass()',
    },
})
export class HlmMenuSeparatorComponent {
    public readonly userClass = input<ClassValue>('', { alias: 'class' });
    protected _computedClass = computed(() =>
        hlm('block -mx-1 my-1 h-px bg-muted', this.userClass()),
    );
}
