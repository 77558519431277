/**
 * @packageDocumentation
 * @module api.functional.api
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Primitive, Resolved } from "typia";
import typia from "typia";

export * as authentication from "./authentication";
export * as users from "./users";
export * as inbox from "./inbox";
export * as threads from "./threads";
export * as emails from "./emails";
export * as labels from "./labels";
export * as boards from "./boards";

/**
 * @controller HealthcheckController.healthcheck
 * @path GET /api/healthcheck
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function healthcheck(
  connection: IConnection,
): Promise<healthcheck.Output> {
  return !!connection.simulate
    ? healthcheck.simulate(connection)
    : PlainFetcher.fetch(connection, {
        ...healthcheck.METADATA,
        template: healthcheck.METADATA.path,
        path: healthcheck.path(),
      });
}
export namespace healthcheck {
  export type Output = Primitive<string>;

  export const METADATA = {
    method: "GET",
    path: "/api/healthcheck",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = () => "/api/healthcheck";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<string>> => typia.random<Primitive<string>>(g);
  export const simulate = (connection: IConnection): Output => {
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
