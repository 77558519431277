import { computed, Directive, input } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { BrnMenuItemCheckboxDirective } from '@spartan-ng/ui-menu-brain';
import { ClassValue } from 'clsx';

@Directive({
    selector: '[hlmMenuItemCheckbox]',
    standalone: true,
    host: {
        '[class]': '_computedClass()',
    },
    hostDirectives: [
        {
            directive: BrnMenuItemCheckboxDirective,
            inputs: ['disabled: disabled', 'checked: checked'],
            outputs: ['triggered: triggered'],
        },
    ],
})
export class HlmMenuItemCheckboxDirective {
    public readonly userClass = input<ClassValue>('', { alias: 'class' });
    protected _computedClass = computed(() =>
        hlm(
            'group w-full relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:bg-accent focus-visible:text-accent-foreground disabled:pointer-events-none disabled:opacity-50',
            this.userClass(),
        ),
    );
}
