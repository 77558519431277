import { inject, Injectable } from '@angular/core';
import { injectMutation, injectQueryClient } from '@tanstack/angular-query-experimental';
import { selectSelectedUserId, UserState } from '../../users/store/user.reducer';
import { Store } from '@ngrx/store';
import { Label } from '../models/label.model';
import { LabelsService } from '@web/app/modules/inbox/services/labels.service';
import { toast } from 'ngx-sonner';

@Injectable({
    providedIn: 'root',
})
export class LabelMutations {
    private readonly _labelService = inject(LabelsService);
    private readonly _store = inject(Store<UserState>);
    private readonly _currentUserId = this._store.selectSignal(selectSelectedUserId);
    private readonly queryClient = injectQueryClient();
    

    readonly createLabelMutation = injectMutation(() => ({
        mutationFn: (params: Label) => this._labelService.createLabel(params),
        onMutate: (label) => {
            let oldData = this.queryClient.getQueryData([this._currentUserId()!, 'labels']);
            this.queryClient.setQueryData([this._currentUserId()!, 'labels'], (oldData: any) => {
                const shouldUpdate = oldData.data.find((l: Label) => l.id === label.id);
                if (shouldUpdate) {
                    return {
                        data: oldData.data.map((l: Label) => l.id === label.id ? label : l),
                    };
                }
                return {
                    data: [...oldData.data, label],
                };
            })

            return {previousData: oldData};
        },
        onError: (_error, _addedLabel, context: any) => {
            this.queryClient.setQueryData([this._currentUserId()!, 'labels'], (oldData: any) => {
                return context?.previousData
            });
            toast.error('Error creating label');
        },
    }));

    readonly deleteLabelMutation = injectMutation(() => ({
        mutationFn: (params: string) => this._labelService.deleteLabel(params),
        onMutate: (labelId) => {
            let oldData = this.queryClient.getQueryData([this._currentUserId()!, 'labels']);
            this.queryClient.setQueryData([this._currentUserId()!, 'labels'], (oldData: any) => {
                return {
                    data: oldData.data.filter((label: Label) => label.id !== labelId),
                };
            })

            return {previousData: oldData};
        },
        onError: (_error, _addedLabel, context: any) => {
            this.queryClient.setQueryData([this._currentUserId()!, 'labels'], (oldData: any) => {
                return context?.previousData
            });
            toast.error('Error deleting label');
        },
    }));

}
