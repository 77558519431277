<header>
    <h1>Recapp Privacy Policy</h1>
    <p class="updated">Last Updated: 15th Jun 2024</p>
</header>

<section class="tldr">
    <h2>TL;DR</h2>
    <p>We never sell your data to 3rd parties. No exceptions.</p>
    <p>All your data is secured through Amazon Web Services.</p>
</section>

<section>
    <h2>1. Introduction</h2>
    <p>
        Welcome to Recapp Tech Private Limited ("Recapp," "we," "our," or "us"). This Privacy Policy is designed to help you understand how
        we collect, use, disclose, and safeguard your personal information when you use our product Recapp, and visit our website
        recapp-app.com (collectively, "Services"). By using our Services, you consent to the practices described in this Privacy Policy.
    </p>
</section>

<section>
    <h2>2. Information We Collect</h2>
    <h3>2.1. Personal Information</h3>
    <ul>
        <li>Contact Information: Such as your name, email address and phone number.</li>
        <li>Account Information: If you create an account, we collect your username and password.</li>
        <li>
            Communications: We may collect information from your communications with us, such as feedback, reviews, or support requests.
        </li>
    </ul>

    <h3>2.2. Usage Information</h3>
    <ul>
        <li>
            Device Information: Information about the device you use to access our Services, such as your device's unique identifier,
            operating system, and browser type.
        </li>
        <li>
            Log Data: Information about your use of our Services, including the pages you visit, the features you use, your search queries,
            and the date and time of your interactions.
        </li>
    </ul>

    <h3>2.3. Cookies and Tracking Technologies</h3>
    <p>We use cookies and similar tracking technologies to collect information about your browsing behavior and preferences.</p>
</section>

<section>
    <h2>3. How We Use Your Information</h2>
    <p>We may use the information we collect for various purposes, including:</p>
    <ul>
        <li>To provide and maintain our services.</li>
        <li>To personalize and improve your experience.</li>
        <li>To communicate with you about our services, promotions, and updates.</li>
        <li>To respond to your requests and provide customer support.</li>
        <li>To monitor and analyze usage patterns and trends.</li>
        <li>To protect the security and integrity of our Services.</li>
        <li>To comply with legal obligations.</li>
    </ul>
</section>

<section>
    <h2>4. Disclosure of Your Information</h2>
    <p>
        We never sell your data to any 3rd party. However, we might share your contact data with established 3rd party services like
        MailChimp to be able to send you email notifications.
    </p>
</section>

<section>
    <h2>5. Your Choices</h2>
    <ul>
        <li>
            Access, Correct, or Delete: You can access, correct, or delete your personal information by logging into your account or
            contacting us.
        </li>
        <li>
            Opt-Out: You can opt-out of receiving promotional communications from us by following the instructions provided in those
            communications.
        </li>
        <li>Cookies: You can control cookies through your browser settings.</li>
        <li>Location Information: You can disable location services on your device.</li>
    </ul>
</section>

<section>
    <h2>6. Data Security</h2>
    <p>
        We implement reasonable security measures to protect your personal information. All your data is secured through Google Cloud
        service.
    </p>
</section>

<section>
    <h2>7. Changes to this Privacy Policy</h2>
    <p>
        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or
        regulatory reasons. We will provide notice of any material changes.
    </p>
</section>

<section class="contact">
    <h2>8. Contact Us</h2>
    <p>
        If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please contact us at:
    </p>
    <address>
        <a href="mailto:support@recapp-app.com">support{{ '@' }}recapp-app.com</a>
    </address>
</section>

<section>
    <h2>9. Applicability</h2>
    <p>This Privacy Policy applies to the use of our Services by users located worldwide.</p>
</section>

<footer>
    <p>Thank you for using Recapp!</p>
</footer>
