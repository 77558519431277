<hlm-dialog [hasBackdrop]="false" [state]="'open'">
    <hlm-dialog-content class=" bg-white" *brnDialogContent="let ctx">
        <hlm-dialog-header>
            <h3 brnDialogTitle hlm>{{ 'sidebar.labels.new_label_modal.title' | translate }}</h3>
        </hlm-dialog-header>

        <hlm-input-form-field>
            <input
                class="w-full"
                type="text"
                hlmInput
                placeholder="{{ 'sidebar.labels.new_label_modal.label_name_placeholder' | translate }}"
                [(ngModel)]="labelText" />
        </hlm-input-form-field>

        <label class="flex items-center" hlmLabel>{{ 'sidebar.labels.new_label_modal.background_color' | translate }}</label>
        <hlm-input-form-field>
            <div class="flex flex-wrap gap-2">
                @for (color of availableColors; track $index) {
                    <div
                        class="grid h-5 w-5 place-items-center rounded-full transition-all hover:scale-150 hover:cursor-pointer hover:border"
                        [style.background]="color"
                        [ngClass]="{
                            'scale-150 border-[1px] border-secondary-accent': color === backgroundColor()
                        }"
                        (click)="selectBackgroundColor(color)">
                        <span class="text-xs" [style.color]="getCorrespondingTextColorForContrast(color)">a</span>
                    </div>
                }
            </div>
        </hlm-input-form-field>

        <label class="mt-5 flex cursor-pointer items-center" hlmLabel>
            <hlm-checkbox class="mr-2" [checked]="shouldBeSubLabel()" (changed)="shouldBeSubLabel.set($event)" />
            {{ 'sidebar.labels.new_label_modal.checkbox_sub_label' | translate }}
        </label>
        <brn-select class="inline-block" [(ngModel)]="selectedParentLabelId" [disabled]="!shouldBeSubLabel()">
            <hlm-select-trigger class="w-full">
                <hlm-select-value  />
            </hlm-select-trigger>
            <hlm-select-content>
                @for (label of rootLabels(); track $index) {
                    <hlm-option [value]="label.id">{{ label.name }}</hlm-option>
                }
            </hlm-select-content>
        </brn-select>

        <hlm-dialog-footer>
            <button hlmBtn [disabled]="!canSubmit()" (click)="createLabel()">{{ !!dialogData.label?.id ? ('common.save' | translate) : ('common.create' | translate) }}</button>
        </hlm-dialog-footer>
    </hlm-dialog-content>
</hlm-dialog>

<style>
    .color-picker {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    .color-circle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        cursor: pointer;
        border: 2px solid transparent;
    }

    .color-circle.selected {
        border: 2px solid #000;
    }
</style>
