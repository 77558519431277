import { Routes } from '@angular/router';

import { LOGGED_IN_ROUTES } from '../routes/logged-in/logged-in.routes';
import { SidenavComponent } from './sidenav/sidenav.component';

export const GLOBAL_LAYOUT_ROUTER: Routes = [
    {
        path: '',
        component: SidenavComponent,
        children: [
            {
                path: '',
                providers: [],
                resolve: {},
                loadChildren: () => LOGGED_IN_ROUTES,
            },
        ],
    },
];
