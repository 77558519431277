import { BaseEntity, EmailState, EntityConstructor, Importance, PlatformKey, SystemLabel } from '@recapp/shared-types';
import { DateTime } from 'luxon';
import { Attachment } from './attachment.model';
import { Label, LabelType } from './label.model';
import { EmailAuthorDTO, EmailDTO } from '@recapp/dto';

type EmailProps = EntityConstructor<Email>;

export class Email extends BaseEntity {
    author: EmailAuthor;
    destination: EmailAuthor[];
    cc?: EmailAuthor[];
    replyTo?: EmailAuthor[];
    subject: string;
    snippet: string;
    content: string;
    state: EmailState;
    importance: Importance;
    attachments: Attachment[];
    labels: Label[];
    socialCreatedAt?: Date;
    platformKey: PlatformKey;
    socialId?: string | undefined;
    threadId: string;
    userId: string;
    analyzedAt?: Date;
    summary?: string;
    inReplyToMessageId?: string;
    references?: string;
    messageId?: string;

    constructor(props: EmailProps) {
        super(props);
        this.author = new EmailAuthor(props.author);
        this.destination = props.destination?.map((destination) => new EmailAuthor(destination));
        this.cc = props.cc?.map((cc) => new EmailAuthor(cc));
        this.replyTo = props.replyTo?.map((replyTo) => new EmailAuthor(replyTo));
        this.subject = props.subject;
        this.snippet = props.snippet;
        this.content = props.content;
        this.state = props.state;
        this.importance = props.importance;
        this.attachments = props.attachments.map((attachment) => new Attachment(attachment));
        this.labels = props.labels.map((label) => new Label(label));
        this.platformKey = props.platformKey;
        this.socialCreatedAt = props.socialCreatedAt ? new Date(props.socialCreatedAt) : undefined;
        this.socialId = props.socialId;
        this.threadId = props.threadId;
        this.userId = props.userId;
        this.analyzedAt = props.analyzedAt;
        this.summary = props.summary;
        this.inReplyToMessageId = props.inReplyToMessageId;
        this.references = props.references;
        this.messageId = props.messageId;
    }

    static fromDto(dto: EmailDTO): Email {
        return new Email({
            id: dto.id,
            attachments: dto.attachments.map((attachment) => Attachment.fromDto(attachment)),
            destination: dto.destination?.map((destination) => EmailAuthor.fromDto(destination)),
            cc: dto.cc?.map((cc) => EmailAuthor.fromDto(cc)),
            author: EmailAuthor.fromDto(dto.author),
            content: dto.content,
            importance: dto.importance,
            labels: dto.labels.map((label) => Label.fromDto(label)),
            snippet: dto.snippet,
            state: dto.state,
            subject: dto.subject,
            createdAt: new Date(dto.createdAt),
            updatedAt: new Date(dto.updatedAt),
            socialCreatedAt: dto.socialCreatedAt ? new Date(dto.socialCreatedAt) : undefined,
            platformKey: dto.platformKey,
            socialId: dto.socialId,
            threadId: dto.threadId,
            userId: dto.userId,
            analyzedAt: dto.analyzedAt ? new Date(dto.analyzedAt) : undefined,
            summary: dto.summary,
            inReplyToMessageId: dto.inReplyToMessageId,
            messageId: dto.messageId,
            references: dto.references,
            replyTo: dto.replyTo?.map((replyTo) => EmailAuthor.fromDto(replyTo)),
        });
    }

    static createEmptyAnswer({
        destination,
        replyTo,
        author,
        threadId,
        userId,
        platformKey,
        subject,
        labels,
        inReplyToMessageId,
        cc,
        references,
    }: {
        destination: EmailAuthor[];
        replyTo?: EmailAuthor[];
        cc?: EmailAuthor[];
        author: EmailAuthor;
        inReplyToMessageId?: string;
        threadId: string;
        userId: string;
        platformKey: PlatformKey;
        subject: string;
        labels: Label[];
        references?: string;
    }): Email {
        return new Email({
            inReplyToMessageId,
            attachments: [],
            destination,
            cc: cc ?? [],
            replyTo,
            author,
            content: '',
            importance: Importance.NORMAL,
            labels,
            snippet: '',
            state: EmailState.DRAFT,
            subject: subject,
            createdAt: new Date(),
            updatedAt: new Date(),
            socialCreatedAt: new Date(),
            platformKey,
            threadId,
            socialId: undefined,
            userId,
            references,
        });
    }

    toDto(): EmailDTO {
        return {
            id: this.id,
            attachments: this.attachments.map((attachment) => attachment.toDto()),
            destination: this.destination?.map((destination) => destination.toDto()),
            cc: this.cc?.map((cc) => cc.toDto()),
            author: this.author.toDto(),
            content: this.content,
            importance: this.importance,
            labels: this.labels.map((label) => label.toDto()),
            snippet: this.snippet,
            state: this.state,
            subject: this.subject,
            createdAt: this.createdAt.toISOString(),
            updatedAt: this.updatedAt.toISOString(),
            socialCreatedAt: this.socialCreatedAt?.toISOString(),
            platformKey: this.platformKey,
            socialId: this.socialId,
            threadId: this.threadId,
            userId: this.userId,
            analyzedAt: this.analyzedAt?.toISOString(),
            summary: this.summary,
            inReplyToMessageId: this.inReplyToMessageId,
            references: this.references,
            replyTo: this.replyTo?.map((replyTo) => replyTo.toDto()),
        };
    }

    copyWith(props: Partial<EmailProps>): Email {
        return new Email({
            ...this,
            ...props,
        });
    }

    getFormattedDate(): string {
        if (DateTime.now().diff(DateTime.fromJSDate(this.socialCreatedAt ?? this.createdAt), 'days').days > 7) {
            return DateTime.fromJSDate(this.socialCreatedAt ?? this.createdAt).toLocaleString(DateTime.DATE_SHORT);
        }
        return DateTime.fromJSDate(this.socialCreatedAt ?? this.createdAt).toRelative()!;
    }

    getFormattedDateRelativeToDay(): string | null {
        return DateTime.fromJSDate(this.socialCreatedAt ?? this.createdAt).toRelativeCalendar({
            unit: 'days'
        })
    }

    getExactDate(): string {
        return DateTime.fromJSDate(this.socialCreatedAt ?? this.createdAt).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
    }

    getDay(): string {
        if (DateTime.now().diff(DateTime.fromJSDate(this.socialCreatedAt ?? this.createdAt), 'days').days > 7) {
            return DateTime.fromJSDate(this.socialCreatedAt ?? this.createdAt).toLocaleString(DateTime.DATE_SHORT);
        }
        return DateTime.fromJSDate(this.socialCreatedAt ?? this.createdAt).toRelative()!;
    }

    isUnread(): boolean {
        return this.labels.some((label) => label.name === SystemLabel.UNREAD);
    }

    isTrashed(): boolean {
        return this.labels.some((label) => label.name === SystemLabel.TRASH);
    }

    isFollowed(): boolean {
        return this.labels.some((label) => label.name === SystemLabel.FOLLOWED);
    }

    isArchived(): boolean {
        return this.labels.some((label) => label.name === SystemLabel.ARCHIVED);
    }

    isNotification(): boolean {
        return this.labels.some((label) => label.name === SystemLabel.NOTIFICATION);
    }

    getDestinations(currentUserEmail: string): string[] {
        return Array.from(
            new Set(this.destination.map((destination) => (destination.emailAdress === currentUserEmail ? 'You' : destination.emailAdress)))
        );
    }

    isDraft(): boolean {
        return this.labels.some((label) => label.name === SystemLabel.DRAFT);
    }

    isSent(): boolean {
        return this.labels.some((label) => label.name === SystemLabel.SENT);
    }

    markEmailAsSent(sentLabel: Label): Email {
        return this.copyWith({
            labels: [...this.labels.filter((label) => label.name !== SystemLabel.DRAFT), sentLabel],
        });
    }

    getDisplayableLabels(): Label[] {
        return this.labels.filter((label) => label.type === LabelType.USER);
    }

    isFromMe(): boolean {
        return this.isDraft() || this.isSent();
    }

    removeLabel(label: Label): Email {
        return this.copyWith({
            labels: this.labels.filter((l) => l.id !== label.id),
        });
    }

    addLabel(label: Label): Email {
        return this.copyWith({
            labels: [...this.labels, label],
        });
    }
}

type EmailAuthorProps = EntityConstructor<EmailAuthor>;

export class EmailAuthor extends BaseEntity {
    name: string;
    emailAdress: string;
    isVerified: boolean;
    avatarUrl?: string;

    constructor(props: EmailAuthorProps) {
        super(props);
        this.name = props.name;
        this.emailAdress = props.emailAdress;
        this.isVerified = props.isVerified;
    }

    static fromDto(dto: EmailAuthorDTO): EmailAuthor {
        return new EmailAuthor({
            id: dto.id,
            name: dto.name.replace(/"/g, ''),
            emailAdress: dto.emailAdress,
            isVerified: dto.isVerified,
            avatarUrl: dto.avatarUrl,
            createdAt: new Date(dto.createdAt),
            updatedAt: new Date(dto.updatedAt),
        });
    }

    toDto(): EmailAuthorDTO {
        return {
            id: this.id,
            name: this.name,
            emailAdress: this.emailAdress,
            isVerified: this.isVerified,
            avatarUrl: this.avatarUrl,
            createdAt: this.createdAt.toISOString(),
            updatedAt: this.updatedAt.toISOString(),
        };
    }
}
