<div class="h-full w-full">
    <div class="logo absolute left-5 top-5 text-3xl font-bold" style="font-family: 'StabliGrotesk'">
        <a routerLink="/">Recapp</a>
    </div>

    <div class="grid h-full w-full place-items-center md:p-4">
        <div class="w-[3 0%] flex flex-col gap-8">
            <!-- TITLE SECTION -->
            <div class="title flex flex-col gap-2">
                <img class="mb-4 dark:invert" height="70" width="70" [src]="'recapp' | svgLogoPathResolver" />
                <h1 class="text-3xl font-semibold">Welcome Back !</h1>
                <p class="text-sm text-subtitle">
                    {{ 'auth.login.subtitle' | translate }}
                </p>
            </div>

            <!-- FORM SECTION -->
            <form class="w-full" [formGroup]="loginForm">
                <div class="flex flex-col gap-2">
                    <div class="flex flex-col gap-2">
                        <input type="email" hlmInput placeholder="johndoe@example.com" formControlName="email" />

                        <hlm-input-form-field>
                            <input
                                class="w-full"
                                hlmInput
                                formControlName="password"
                                placeholder="{{ 'auth.login.password.placeholder' | translate }}"
                                [type]="hidePassword ? 'password' : 'text'" />
                            <button class="p-0" type="button" variant="link" size="sm" hlmBtn hlmInputSuffix (click)="toggleHidePassword()">
                                <span>
                                    @if (hidePassword) {
                                        {{ 'common.show' | translate }}
                                    } @else {
                                        {{ 'common.hide' | translate }}
                                    }
                                </span>
                            </button>
                        </hlm-input-form-field>
                    </div>

                    <div class="mt-4 flex w-full flex-col items-center gap-y-2">
                        <button class="w-full" type="submit" hlmBtn [disabled]="isLoggingIn()" (click)="login()">
                            @if (isLoggingIn()) {
                                <hlm-icon class="mr-2 animate-spin" name="lucideLoader2" size="sm" />
                            }
                            <span>{{ 'auth.login.submit' | translate }}</span>
                        </button>
                        <div class="flex justify-end">
                            <button
                                class="text-xs text-secondary-accent"
                                hlmBtn
                                variant="link"
                                size="sm"
                                (click)="navigateToForgotPassword()">
                                {{ 'auth.login.forgot-password' | translate }}
                            </button>
                        </div>

                        <div class="mt-4 flex w-full items-center justify-center gap-4 text-subtitle">
                            <brn-separator class="flex-1" hlmSeparator />
                            <span class="text-sm">{{ 'auth.login.no-account' | translate }}</span>
                            <brn-separator class="flex-1" hlmSeparator />
                        </div>
                        <button
                            class="relative mt-2 flex w-full items-center justify-center gap-x-6"
                            hlmBtn
                            variant="outline"
                            [disabled]="loadingRedirectUrlForGoogle()"
                            (click)="loginWithGoogle()">
                            <img class="absolute left-3" src="assets/logos/google.svg" height="30" width="30" />
                            <span>
                                {{ 'auth.login.google' | translate }}
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
