/**
 * @packageDocumentation
 * @module api.functional.api.inbox.sync.status
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Primitive, Resolved } from "typia";
import typia from "typia";

import type { GetUserInboxSyncStatusQueryResult } from "@recapp/dto";
import type { ApiResult } from "@recapp/shared-types";

/**
 * @controller InboxController.getInboxSyncStatus
 * @path GET /api/inbox/sync/:userId/status
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getInboxSyncStatus(
  connection: IConnection,
  userId: string,
): Promise<getInboxSyncStatus.Output> {
  return !!connection.simulate
    ? getInboxSyncStatus.simulate(connection, userId)
    : PlainFetcher.fetch(connection, {
        ...getInboxSyncStatus.METADATA,
        template: getInboxSyncStatus.METADATA.path,
        path: getInboxSyncStatus.path(userId),
      });
}
export namespace getInboxSyncStatus {
  export type Output = Primitive<
    ApiResult<GetUserInboxSyncStatusQueryResult, never>
  >;

  export const METADATA = {
    method: "GET",
    path: "/api/inbox/sync/:userId/status",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = (userId: string) =>
    `/api/inbox/sync/${encodeURIComponent(userId?.toString() ?? "null")}/status`;
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<GetUserInboxSyncStatusQueryResult, never>>> =>
    typia.random<
      Primitive<ApiResult<GetUserInboxSyncStatusQueryResult, never>>
    >(g);
  export const simulate = (connection: IConnection, userId: string): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(userId),
      contentType: "application/json",
    });
    assert.param("userId")(() => typia.assert(userId));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
