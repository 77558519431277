import {
    ChangeDetectionStrategy,
    Component,
    computed,
    input,
    signal,
    ViewEncapsulation,
} from '@angular/core';
import { hlm, injectExposesStateProvider } from '@spartan-ng/ui-core';
import { ClassValue } from 'clsx';

@Component({
    selector: 'hlm-alert-dialog-content',
    standalone: true,
    host: {
        '[class]': '_computedClass()',
        '[attr.data-state]': 'state()',
    },
    template: ` <ng-content /> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class HlmAlertDialogContentComponent {
    private readonly _stateProvider = injectExposesStateProvider({
        optional: true,
        host: true,
    });
    public readonly state = this._stateProvider?.state ?? signal('closed');

    public readonly userClass = input<ClassValue>('', { alias: 'class' });
    protected readonly _computedClass = computed(() =>
        hlm(
            'relative grid w-full max-w-lg gap-4 border-border border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-top-[2%]  data-[state=open]:slide-in-from-top-[2%] sm:rounded-lg md:w-full',
            this.userClass(),
        ),
    );
}
