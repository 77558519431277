import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { userReducer, UserState } from '../../modules/users/store/user.reducer';
import { localStorageSync, rehydrateApplicationState } from 'ngrx-store-localstorage';
import { inboxReducer, InboxState } from '@web/app/modules/inbox/store/inbox.reducer';
export interface State {
    user: UserState;
    inbox: InboxState;
}
// actions.ts
const STORAGE = '@ngrx/store/storage';

export class Storage implements Action {
    readonly type = STORAGE;
    constructor(readonly payload: string) {}
}
export const reducers: ActionReducerMap<State> = { user: userReducer, inbox: inboxReducer };
// reducers.ts

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state: State, action: any) => {
        const keys: string[] = ['user', 'inbox'];

        if (action.type === STORAGE) {
            const rehydratedState = rehydrateApplicationState([action.payload], localStorage, (k) => k, true);
            console.log('localStorageSyncReducer', state, action, rehydratedState);
            return { ...state, ...rehydratedState };
        }

        return localStorageSync({
            keys,
            rehydrate: true,
        })(reducer)(state, action);
    };
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
