import { Directive, computed, input } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { ClassValue } from 'clsx';

@Directive({
    selector: 'brn-switch-thumb[hlm],[hlmSwitchThumb]',
    standalone: true,
    host: {
        '[class]': '_computedClass()',
    },
})
export class HlmSwitchThumbDirective {
    public readonly userClass = input<ClassValue>('', { alias: 'class' });
    protected _computedClass = computed(() =>
        hlm(
            'block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform group-data-[state=checked]:translate-x-5 group-data-[state=unchecked]:translate-x-0',
            this.userClass(),
        ),
    );
}
