import { Routes } from '@angular/router';
import { AUTH_ROUTES } from '@web/app/modules/auth/routes/auth.routing';
import { WEBSITE_ROUTES } from '@web/app/modules/website/pages/website-root.routing';
import { ShouldRedirectToAuthGuard } from './guards/should-redirect-to-auth.guard';

export const LOGGED_OUT_ROUTES: Routes = [
    {
        path: '',
        canActivate: [ShouldRedirectToAuthGuard],
        loadChildren: () => WEBSITE_ROUTES,
    },
    {
        path: 'auth',
        loadChildren: () => AUTH_ROUTES,
    },
];
