import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { IsActiveMatchOptions, Router, RouterOutlet } from '@angular/router';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, computed, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
    HlmAvatarImageDirective,
    HlmAvatarComponent,
    HlmAvatarFallbackDirective,
    HlmButtonDirective,
    HlmIconComponent,
    provideIcons,
    HlmSeparatorDirective,
    HlmMenuShortcutComponent,
    HlmMenuComponent,
    HlmMenuGroupComponent,
    HlmMenuItemDirective,
    HlmMenuItemIconDirective,
    HlmMenuLabelComponent,
    HlmMenuSeparatorComponent,
    HlmSwitchComponent,
} from '@recapp/ui';
import { AvatarPipe } from '@web/app/shared/pipes/avatar.pipe';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    lucidePencil,
    lucideSearch,
    lucideSettings2,
    lucideInbox,
    lucideSend,
    lucideList,
    lucideLogOut,
    lucideUserPlus,
    lucideMessageCircle,
    lucidePlusCircle,
    lucideSun,
    lucideMoon,
} from '@ng-icons/lucide';
import { BrnSeparatorComponent } from '@spartan-ng/ui-separator-brain';
import { BrnMenuTriggerDirective } from '@spartan-ng/ui-menu-brain';
import { State } from '../../store/storage-meta';
import { Store } from '@ngrx/store';
import { logoutAllUsers, logoutUser, selectUser } from '@web/app/modules/users/store/user.actions';
import { UsersContext } from '../../context/users.context';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { selectAllUsers } from '@web/app/modules/users/store/user.reducer';
import { User } from '@web/app/modules/users/entities/user.model';
import { toast } from 'ngx-sonner';
import { LocalStorageKey } from '@web/app/shared/enums/local-storage-key';
import { NavItemComponent } from '@web/app/core/layout/sidenav/components/nav-item/nav-item.component';
import { SidenavContext } from '@web/app/core/layout/sidenav/context/sidenav.context';
import { SidenavLabelsComponent } from '@web/app/core/layout/sidenav/components/sidenav-labels/sidenav-labels.component';
import { InboxContext } from '@web/app/modules/inbox/contexts/inbox.context';
import { InboxQueries } from '@web/app/modules/inbox/contexts/inbox.queries';
import { CustomDialogService } from '@web/app/shared/services/custom-dialog.service';
import { GlobalSearchComponent } from '@web/app/core/layout/sidenav/components/global-search/global-search.component';

/** @title Sidenav open & close behavior */
@Component({
    selector: 'sidenav',
    templateUrl: 'sidenav.component.html',
    styleUrl: 'sidenav.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        // Angular
        FormsModule,
        NgTemplateOutlet,

        // Pipes
        AvatarPipe,

        // Material
        MatSidenavModule,
        MatCheckboxModule,
        MatButtonModule,
        RouterOutlet,
        MatListModule,
        MatIconModule,

        // UI
        HlmAvatarComponent,
        HlmAvatarImageDirective,
        HlmAvatarFallbackDirective,
        HlmSeparatorDirective,
        HlmButtonDirective,
        HlmIconComponent,
        HlmMenuComponent,
        HlmMenuGroupComponent,
        HlmMenuItemDirective,
        HlmMenuItemIconDirective,
        HlmMenuLabelComponent,
        HlmMenuSeparatorComponent,
        HlmMenuShortcutComponent,
        BrnSeparatorComponent,
        BrnMenuTriggerDirective,
        TranslateModule,
        HlmSwitchComponent,
        NgClass,
        NavItemComponent,
        SidenavLabelsComponent
    ],
    providers: [
        provideIcons({
            lucidePencil,
            lucideSearch,
            lucideSettings2,
            lucideInbox,
            lucideSend,
            lucideList,
            lucideLogOut,
            lucideUserPlus,
            lucideMessageCircle,
            lucidePlusCircle,
            lucideMoon,
            lucideSun,
        }),
    ],
})
export class SidenavComponent implements OnInit, OnDestroy {
    private readonly _router = inject(Router);
    private readonly _store = inject(Store<State>);
    private readonly _translateService = inject(TranslateService);
    private readonly _inboxContext = inject(InboxContext);
    private readonly _dialog = inject(CustomDialogService);
    readonly usersContext = inject(UsersContext);
    readonly sidenavContext = inject(SidenavContext);
    readonly inboxQueries = inject(InboxQueries);

    readonly OPENED_WIDTH = 232;
    readonly CLOSED_WIDTH = 75;

    readonly ROUTER_ACTIVE_MATCH_OPTIONS: IsActiveMatchOptions = {
        queryParams: 'ignored',
        matrixParams: 'exact',
        paths: 'subset',
        fragment: 'ignored',
    };

    readonly allOtherUsers = computed(() => {
        const allUsers = this._store.selectSignal(selectAllUsers)();
        const currentUser = this.usersContext.currentUser();
        return allUsers.filter((user) => user.id !== currentUser?.id);
    });

    ngOnInit(): void {
        this._handleSidenavHover();
        document.addEventListener('keydown', this._listenToCommandTool.bind(this));
    }

    ngOnDestroy(): void {
        document.removeEventListener('keydown', this._listenToCommandTool.bind(this));
    }

    logout(): void {
        localStorage.removeItem(`${this.usersContext.currentUser()!.id}_${LocalStorageKey.JWT_TOKEN}`);
        localStorage.removeItem(`${this.usersContext.currentUser()!.id}_${LocalStorageKey.USER_ID}`);
        const nextUser = this.allOtherUsers()[0];
        this._store.dispatch(logoutUser());
        if (!nextUser) {
            window.location.reload();
            return;
        }
        this.changeUser(nextUser);
    }

    logoutAll(): void {
        for (const user of this._store.selectSignal(selectAllUsers)()) {
            localStorage.removeItem(`${user.id}_${LocalStorageKey.JWT_TOKEN}`);
            localStorage.removeItem(`${user.id}_${LocalStorageKey.USER_ID}`);
        }

        this._store.dispatch(logoutAllUsers());

        window.location.reload();
    }

    addAccount(): void {
        window.open(this._router.serializeUrl(this._router.createUrlTree(['auth/login'])), '_blank');
    }

    changeUser(user: User): void {
        this._store.dispatch(selectUser({ id: user.id }));
        toast.info(`${this._translateService.instant('common.switched_to')} ${user.email}`, {
            position: 'bottom-center',
        });
    }

    toggleDarkMode($event: boolean) {
        const isPresent = document.documentElement.classList.toggle('dark', $event);
        localStorage.setItem('recapp-theme', isPresent ? 'dark' : 'light');
    }

    isDarkMode(): boolean {
        return document.documentElement.classList.contains('dark');
    }
    
    selectInbox(): void {
        this._inboxContext.changeCurrentSelectedLabels(this._inboxContext.allLabels)
    }

    private _handleSidenavHover(): void {
        document.getElementById('sidenav')?.addEventListener('mouseenter', () => {
            this.sidenavContext.opened.set(true);
        });

        document.getElementById('sidenav')?.addEventListener('mouseleave', () => {
            const avatarContextMenu = document.getElementsByTagName('hlm-menu');
            if (avatarContextMenu.length !== 0) {
                setTimeout(() => {
                    document.getElementById('sidenav')?.dispatchEvent(new Event('mouseleave'));
                }, 500);
                return;
            }
            if (document.getElementById('sidenav')?.matches(':hover')) {
                return;
            }
            this.sidenavContext.opened.set(false);
        });
    }

    openCommandTool(): void {
        const isAlreadyOpen = document.querySelector('.cmdk-command');
        if (isAlreadyOpen) {
            this._dialog.closeAll();
            return;
        }
        this._dialog.open(GlobalSearchComponent, {
            width: '70%',
            height: '50%',
            position: {
                top: '4%'
            },
            panelClass: ['rounded-xl', '!bg-light-bg'],
            hasBackdrop: false
        });        
    }

    private _listenToCommandTool(event: KeyboardEvent): void { 
        if (event.metaKey && event.key === 'k') {
            this.openCommandTool();
        }
    }
}
