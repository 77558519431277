import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { lucideMoreVertical, lucidePlusCircle } from '@ng-icons/lucide';
import { TranslateModule } from '@ngx-translate/core';
import { HlmIconComponent, HlmMenuModule, provideIcons } from '@recapp/ui';
import { BrnMenuTriggerDirective } from '@spartan-ng/ui-menu-brain';
import { SidenavContext } from '@web/app/core/layout/sidenav/context/sidenav.context';
import { InboxContext } from '@web/app/modules/inbox/contexts/inbox.context';
import { InboxQueries } from '@web/app/modules/inbox/contexts/inbox.queries';
import { LabelMutations } from '@web/app/modules/inbox/contexts/label.mutations';
import { LabelQueries } from '@web/app/modules/inbox/contexts/label.queries';
import { Label, LabelType } from '@web/app/modules/inbox/models/label.model';
import { LabelModalComponent } from '@web/app/shared/components/label-modal/label-modal.component';
import { ApplyPurePipe } from '@web/app/shared/pipes/apply-fn.pipe';
import { CustomDialogService } from '@web/app/shared/services/custom-dialog.service';

@Component({
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-sidenav-labels',
    templateUrl: './sidenav-labels.component.html',
    styleUrls: ['./sidenav-labels.component.scss'],
    imports: [TranslateModule, NgClass, ApplyPurePipe, HlmIconComponent, HlmMenuModule, BrnMenuTriggerDirective],
    providers: [
        provideIcons({
            lucidePlusCircle,
            lucideMoreVertical,
        }),
    ],
})
export class SidenavLabelsComponent {
    readonly sidenavContext = inject(SidenavContext);
    readonly inboxQueries = inject(InboxQueries);
    readonly labelQueries = inject(LabelQueries);
    readonly labelMutations = inject(LabelMutations);
    readonly inboxContext = inject(InboxContext);
    readonly dialog = inject(CustomDialogService);

    readonly userCustomLabels = computed(() => this.labelQueries.userLabels().filter((label) => label.type !== LabelType.SYSTEM).sort((a, b) => a.name.localeCompare(b.name)));

    readonly rootLabels = computed(() => this.userCustomLabels().filter((label) => !label.isSubLabel()));

    isLabelSelected = (label: string, currentSelectedlabels: string[]): boolean => {
        return currentSelectedlabels.includes(label);
    };

    toggleSelectLabel(label: Label): void {
        if (this.inboxContext.currentSelectedUserLabel()?.name === label.name) {
            this.inboxContext.changeCurrentSelectedUserLabel(null);
            return;
        }
        this.inboxContext.changeCurrentSelectedUserLabel(label);
    }

    openLabelModal(label?: Label): void {
        this.dialog.open(LabelModalComponent, {
            data: {
                label,
            }
        });
    }

    deleteLabel(label: Label): void {
        this.dialog.openAlert({
            data: {
                title: 'Delete label',
                description: 'Are you sure you want to delete this label?',
                actions: {
                    primary: {
                        text: 'Delete',
                        onClick: () => {
                            this.labelMutations.deleteLabelMutation.mutate(label.id);
                            this.dialog.closeAll();
                        },
                    },
                    secondary: {
                        text: 'Cancel',
                        onClick: () => {
                            this.dialog.closeAll();
                        },
                    },
                },
            }
        })
    }
}
