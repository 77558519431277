import { Pipe, PipeTransform } from '@angular/core';

const AVATAR_URL = 'https://api.dicebear.com/8.x/adventurer/svg?seed=';
const DEFAULT_AVATAR = 'https://api.dicebear.com/avatar.svg';

@Pipe({
    name: 'avatar',
    standalone: true,
})
export class AvatarPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return DEFAULT_AVATAR;
        }
        return `${AVATAR_URL}${value}`;
    }
}
