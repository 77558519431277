<cmdk-command class="bg-light-bg p-1 h-full" [loop]="true" [loading]="isLoading()">
   <hlm-input-form-field>
    <input
        cmdkInput
        class="w-full rounded-none border-b border-t-0 border-l-0 border-r-0"
        hlmInput
        (input)="onSearchChange($event)"
        placeholder="{{ 'common.search_placeholder' | translate }}"
        />
      <hlm-icon hlmInputSuffix name="lucideSearch" size="sm" class="text-light"></hlm-icon>
    </hlm-input-form-field>
    @if(!isLoading()) {
      <div *cmdkEmpty class="!m-4 text-sm text-light">No results found.</div>
    } @else {
      <div class="flex w-full items-center justify-center my-4">
          <hlm-spinner size="sm" />
      </div>
    }
  

  <cmdk-list>
    @for (email of filteredThreads(); track $index) {
      <button (selected)="onSelected(email.threadId)" class="hover:bg-light-bg-darker transition-all w-full p-2 mb-2 text-start text-sm text-light flex justify-between items-center" cmdkItem>
        <div class="flex gap-x-2 items-center max-w-[80%]">
          <hlm-icon name="lucideMail" size="sm"></hlm-icon>
          <span class="font-medium flex-2 truncate">{{ email.subject }}</span>
          <span class="truncate flex-3">{{ email.summary ?? email.snippet  ?? email.content}}</span>
          </div>
          <app-email-labels [labels]="email.getDisplayableLabels()" ></app-email-labels>
      </button>
    }
  </cmdk-list>
  
</cmdk-command>