@if (hasLabel) {
    <ng-content select="[hlmLabel]"></ng-content>
}
<div class="relative block {{ class }}">
    @if (hasPrefix) {
        <div
            class="absolute left-3 top-0 flex h-10 items-center justify-center"
        >
            <ng-content select="[hlmInputPrefix]"></ng-content>
        </div>
    }

    <ng-content select="[hlmInput]"></ng-content>

    @if (hasSuffix) {
        <div
            class="absolute right-4 top-0 flex h-10 items-center justify-center"
        >
            <ng-content select="[hlmInputSuffix]"></ng-content>
        </div>
    }
</div>
<ng-content></ng-content>
