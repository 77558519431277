import { ChangeDetectionStrategy, Component, WritableSignal, inject, signal } from '@angular/core';
import { CommonModule, NgStyle } from '@angular/common';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { provideIcons } from '@ng-icons/core';
import { lucideCheckCircle2, lucideLoader2, lucideCircle } from '@ng-icons/lucide';
import { HlmLabelDirective, HlmInputModule, HlmButtonDirective, HlmIconComponent, HlmSpinnerComponent } from '@recapp/ui';
import { toast } from 'ngx-sonner';
import { UsersService } from '@web/app/modules/users/services/users.service';
import { LocalStorageKey } from '@web/app/shared/enums/local-storage-key';

import { Store } from '@ngrx/store';
import { User } from '@web/app/modules/users/entities/user.model';
import { addUser, selectUser } from '@web/app/modules/users/store/user.actions';
import { InboxContext } from '@web/app/modules/inbox/contexts/inbox.context';

@Component({
    selector: 'app-auth-success',
    standalone: true,
    imports: [
        NgStyle,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        RouterLink,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        HlmInputModule,
        HlmButtonDirective,
        HlmIconComponent,
        HlmLabelDirective,
        HlmSpinnerComponent,
    ],
    providers: [provideIcons({ lucideLoader2, lucideCheckCircle2, lucideCircle })],
    templateUrl: './auth-success.component.html',
    styleUrl: './auth-success.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthSuccessComponent {
    private readonly _inboxContext = inject(InboxContext);

    PASSWORD_MIN_LENGTH = 8;

    createPasswordForm = this._formBuilder.group({
        password: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required, Validators.minLength(this.PASSWORD_MIN_LENGTH)],
        }),
    });

    hidePassword = true;

    submittingPassword: WritableSignal<boolean> = signal(false);

    passwordStrength: WritableSignal<number> = signal(0);

    password: WritableSignal<string> = signal('');

    loading = signal(true);

    get passwordControl(): FormControl {
        return this.createPasswordForm.get('password') as FormControl;
    }

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _usersService: UsersService,
        private readonly _router: Router,
        private readonly _store: Store
    ) {
        // check url query params for token and user_id
        this._initLocalStorageFromRedirection();
        this.createPasswordForm.controls.password.valueChanges.subscribe((password) => {
            this.password.set(password);
            this.passwordStrength.set(this._calculatePasswordStrengthPercentage(password));
        });
    }

    submit(): void {
        this.submittingPassword.set(true);
        this._usersService
            .updatePassword({
                password: this.createPasswordForm.getRawValue().password,
                userId: localStorage.getItem(LocalStorageKey.USER_ID)!,
            })
            .subscribe({
                next: (res) => {
                    this.submittingPassword.set(false);
                    const user = User.fromDto(res.data);
                    this._store.dispatch(addUser({ user }));
                    this._store.dispatch(selectUser({ id: user.id }));
                    setTimeout(() => {
                        this._inboxContext.startSync();
                    });
                    this._router.navigate(['/mail']);
                },
                error: (err) => {
                    this.submittingPassword.set(false);
                    toast.error('Oops, une erreur est survenue', {
                        description: JSON.parse(err.message).message,
                    });
                },
            });
    }

    hasUppercase(password: string): boolean {
        return /[A-Z]/.test(password);
    }

    hasNumber(password: string): boolean {
        return /[0-9]/.test(password);
    }

    hasSpecialChar(password: string): boolean {
        return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
    }

    hasMinLength(password: string): boolean {
        return password.length >= this.PASSWORD_MIN_LENGTH;
    }

    private _calculatePasswordStrengthPercentage(password: string): number {
        if (!password) {
            return 0;
        }

        const hasUppercase = this.hasUppercase(password);
        const hasNumber = this.hasNumber(password);
        const hasSpecialChar = this.hasSpecialChar(password);
        const hasMinLength = this.hasMinLength(password);

        let strength = 0;
        if (hasUppercase) {
            strength += 1;
        }
        if (hasNumber) {
            strength += 1;
        }
        if (hasSpecialChar) {
            strength += 1;
        }
        if (hasMinLength) {
            strength += 1;
        }

        return (strength / 4) * 100;
    }

    private _initLocalStorageFromRedirection() {
        const url = new URL(window.location.href);
        const userId = url.searchParams.get(LocalStorageKey.USER_ID) || localStorage.getItem(LocalStorageKey.USER_ID);
        const token = url.searchParams.get(LocalStorageKey.JWT_TOKEN) || localStorage.getItem(`${userId}_${LocalStorageKey.JWT_TOKEN}`);
        const isNewUser = url.searchParams.get('is_new_user');
        if (token && userId) {
            // store token and user_id in local storage
            localStorage.setItem(`${userId}_${LocalStorageKey.JWT_TOKEN}`, token);
            localStorage.setItem(LocalStorageKey.USER_ID, userId);
            // update url
            if (isNewUser === 'false') {
                this._usersService.getUser(userId).subscribe({
                    next: (res) => {
                        const user = res.data;
                        this._store.dispatch(addUser({ user }));
                        this._store.dispatch(selectUser({ id: user.id }));
                        this.loading.set(false);
                        this._router.navigate(['/mail']);
                    },
                });
                return;
            }
            // remove token and user_id from url query params
            url.searchParams.delete(LocalStorageKey.JWT_TOKEN);
            url.searchParams.delete(LocalStorageKey.USER_ID);
            url.searchParams.delete('is_new_user');
            window.history.replaceState({}, document.title, url.toString());
            this.loading.set(false);
        } else {
            console.error('token and user_id not found in url query params');
        }
    }
}
