import { Injectable, inject } from '@angular/core';
import { ApiResult, SyncStatus, SystemLabel } from '@recapp/shared-types';
import { TrpcService } from '@web/app/core/services/trpc.service';
import { Thread } from '../models/thread.model';

@Injectable({
    providedIn: 'root',
})
export class InboxService {
    private readonly _trpcService = inject(TrpcService);

    getInboxThreadsPaginated({
        userId,
        pageNumber,
        pageSize,
        labelsNames = [SystemLabel.INBOX],
        boardColumnId
    }: {
        userId: string;
        pageNumber: number;
        pageSize: number;
        labelsNames?: string[];
        boardColumnId?: string;
    }): Promise<ApiResult<Thread[], { hasMore: boolean; pageNumber: number }>> {
        return this._trpcService.api.inbox.list
            .getInboxPaginated(this._trpcService.connection, userId, {
                pageNumber,
                pageSize,
                labelsNames,
                boardColumnId
            })
            .then(
                (res) =>
                    new ApiResult(
                        res.data.map((tdto) => Thread.fromDto(tdto)),
                        { hasMore: res.metadata!.hasMore, pageNumber }
                    )
            );
    }

    getUnreadThreadsCount(userId: string): Promise<ApiResult<string[]>> {
        return this._trpcService.api.inbox.unread.count
            .getUnreadThreadsCount(this._trpcService.connection, userId)
            .then((res) => new ApiResult(res.data));
    }

    getInboxSyncStatus(userId: string): Promise<ApiResult<{ status: SyncStatus, progress: number }>> {
        return this._trpcService.api.inbox.sync.status
            .getInboxSyncStatus(this._trpcService.connection, userId)
            .then((res) => new ApiResult(res.data));
    }

    startInboxSync(userId: string): Promise<ApiResult<{ success: boolean }>> {
        return this._trpcService.api.inbox.sync.syncInbox(this._trpcService.connection, userId).then((res) => new ApiResult(res.data));
    }
}
