import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { WebsiteRootComponent } from './website-root.component';
import { TermsComponent } from './pages/terms-and-privacy/terms/terms.component';
import { PrivacyComponent } from './pages/terms-and-privacy/privacy/privacy.component';

export const WEBSITE_ROUTES: Routes = [
    {
        path: '',
        component: WebsiteRootComponent,
        children: [
            {
                path: '',
                component: HomeComponent,
            },
            {
                path: 'terms',
                component: TermsComponent,
            },
            {
                path: 'privacy',
                component: PrivacyComponent,
            },
        ],
    },
];
