import { computed, Directive, input } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const cardFooterVariants = cva('flex p-6 pt-0', {
    variants: {
        direction: {
            row: 'flex-row items-center space-x-1.5',
            column: 'flex-col space-y-1.5',
        },
    },
    defaultVariants: {
        direction: 'row',
    },
});
export type CardFooterVariants = VariantProps<typeof cardFooterVariants>;

@Directive({
    selector: '[hlmCardFooter]',
    standalone: true,
    host: {
        '[class]': '_computedClass()',
    },
})
export class HlmCardFooterDirective {
    public readonly userClass = input<ClassValue>('', { alias: 'class' });
    protected _computedClass = computed(() =>
        hlm(
            cardFooterVariants({ direction: this.direction() }),
            this.userClass(),
        ),
    );

    public readonly direction = input<CardFooterVariants['direction']>('row');
}
