<style>
    body {
        font-family: Arial, sans-serif;
        line-height: 1.6;
    }
    .container {
        width: 80%;
        margin: 20px auto;
    }
    h1,
    h2 {
        color: #333;
    }
    .last-updated {
        text-align: center;
        font-style: italic;
        margin-bottom: 20px;
    }
    .tnc-section {
        margin-bottom: 20px;
    }
    a {
        color: #0077cc;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
</style>

<div class="container">
    <h1>Terms and Conditions</h1>
    <p class="updated">Last Updated: 15th Jun 2024</p>

    <!-- Introduction Section -->
    <section class="tnc-section">
        <h2>1. Introduction</h2>
        <p>
            Welcome to Recapp (“Company”, “we”, “our”, “us”). These Terms &amp; Conditions (“Terms”, “Terms &amp; Conditions”, "TnC") govern
            your use of our web pages located at https://recapp-app.com operated by Recapp. By accessing and using this website, you agree
            to be bound by these Terms and Conditions, all applicable laws, and regulations. If you do not agree with any of these terms,
            you are prohibited from using or accessing this site.
        </p>
    </section>

    <!-- Use of the Website Section -->
    <section class="tnc-section">
        <h2>2. Use of the Website</h2>
        <p>
            (a) You are responsible for ensuring that your use of this website complies with all applicable laws and regulations (b) You
            agree not to engage in any unlawful, harmful, or unauthorized activities when using the website.
        </p>
        <!-- Content -->
    </section>

    <!-- Intellectual Property Section -->
    <section class="tnc-section">
        <h2>3. Intellectual Property</h2>
        <!-- Content -->
        <p>
            (a) The content on this website, including text, images, videos, and logos, is protected by copyright and other intellectual
            property rights. You may not use, reproduce, or distribute this content without our explicit permission. (b) You may download or
            print a copy of the website's content for personal, non-commercial use only.
        </p>
    </section>

    <!-- User Accounts Section -->
    <section class="tnc-section">
        <h2>4. User Accounts</h2>
        <!-- Content -->
        <p>
            (a) To access certain features of the website, you may be required to create a user account. You are responsible for maintaining
            the confidentiality of your account information and for all activities that occur under your account. (b) We reserve the right
            to terminate or suspend your account at our discretion if we believe you have violated these Terms and Conditions.
        </p>
    </section>

    <!-- Communications Section -->
    <section class="tnc-section">
        <h2>5. Communications</h2>
        <!-- Content -->
        <p>
            By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials and other
            information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the
            unsubscribe link.
        </p>
    </section>

    <!-- Reserved Usernames Section -->
    <section class="tnc-section">
        <h2>6. Reserved Usernames</h2>
        <p>
            Recapp has the right to maintain a list of reserved names (usernames) which will not be made publicly available. These reserved
            names may be set aside for purposes of proactive trademark protection, avoiding user confusion, security measures, or any other
            reason (or no reason). Additionally, Recapp reserves the right to change any already-claimed name at its sole discretion. In
            such cases, Recapp will make a reasonable effort to find a suitable alternative and assist with any transition-related concerns.
            Attempts to sell, buy, or solicit other forms of payment in exchange for usernames are also violations and may result in
            permanent account suspension.
        </p>
        <!-- Content -->
    </section>

    <!-- Privacy Policy Section -->
    <section class="tnc-section">
        <h2>7. Privacy Policy</h2>
        <p>
            Your use of this website is also governed by our Privacy Policy, which can be accessed here : recapp-app.com/privacy. Please
            review the Privacy Policy to understand how we collect, use, and protect your personal information.
        </p>
        <!-- Content -->
    </section>

    <!-- Limitation of Liability Section -->
    <section class="tnc-section">
        <h2>8. Limitation of Liability</h2>
        <p>
            (a) We strive to provide accurate and up-to-date information on this website, but we make no guarantees regarding the accuracy,
            completeness, or suitability of the information provided. You use the information on this website at your own risk. (b) We will
            not be liable for any direct, indirect, incidental, consequential, or special damages resulting from your use of or inability to
            use this website.
        </p>
        <!-- Content -->
    </section>

    <!-- Links to Third-Party Websites Section -->
    <section class="tnc-section">
        <h2>9. Links to Third-Party Websites</h2>
        <p>
            This website may contain links to third-party websites. These links are provided for your convenience, and we do not endorse or
            control the content on these websites. We are not responsible for the content or actions of third-party websites.
        </p>
        <!-- Content -->
    </section>

    <!-- Changes to the Terms and Conditions Section -->
    <section class="tnc-section">
        <h2>10. Changes to the Terms and Conditions</h2>
        <p>
            We reserve the right to modify these Terms and Conditions at any time. It is your responsibility to review these terms
            regularly. Your continued use of the website after any modifications indicates your acceptance of the updated Terms and
            Conditions.
        </p>
        <!-- Content -->
    </section>

    <!-- Governing Law Section -->
    <section class="tnc-section">
        <h2>11. Governing Law</h2>
        <p>
            These Terms and Conditions are governed by and construed in accordance with the laws of France, and any disputes arising from or
            related to your use of this website will be subject to the exclusive jurisdiction of the courts in France
        </p>
        <!-- Content -->
    </section>

    <!-- Contact Information Section -->
    <section class="tnc-section">
        <h2>12. Contact Information</h2>
        <p>
            If you have any questions or concerns about these Terms and Conditions, please contact us at
            <a href="mailto:support@recapp-app.com">support{{ '@' }}recapp-app.com</a>.
        </p>
    </section>
</div>
