import { ChangeDetectionStrategy, Component, computed, effect, inject, model, signal, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
    HlmDialogComponent,
    HlmDialogContentComponent,
    HlmDialogFooterComponent,
    HlmDialogHeaderComponent,
    HlmInputDirective,
    HlmInputModule,
    HlmButtonDirective,
    HlmCheckboxComponent,
    HlmSelectImports,
    HlmIconComponent,
} from '@recapp/ui';
import { BrnDialogContentDirective } from '@spartan-ng/ui-dialog-brain';
import { Label, LabelType } from '@web/app/modules/inbox/models/label.model';
import { BrnSelectComponent, BrnSelectImports } from '@spartan-ng/ui-select-brain';
import { InboxContext } from '@web/app/modules/inbox/contexts/inbox.context';
import { CustomDialogService } from '@web/app/shared/services/custom-dialog.service';
import { NgClass } from '@angular/common';
import { LabelQueries } from '@web/app/modules/inbox/contexts/label.queries';
import { LabelMutations } from '@web/app/modules/inbox/contexts/label.mutations';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkListbox, CdkListboxModule } from '@angular/cdk/listbox';

export interface EditLabelDialogData {
    label?: Label;
}
@Component({
    selector: 'app-label-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './label-modal.component.html',
    standalone: true,
    imports: [
        TranslateModule,
        HlmDialogComponent,
        HlmDialogContentComponent,
        HlmDialogFooterComponent,
        HlmDialogHeaderComponent,
        BrnDialogContentDirective,
        HlmInputDirective,
        HlmInputModule,
        HlmButtonDirective,
        HlmCheckboxComponent,
        FormsModule,
        HlmIconComponent,
        BrnSelectImports,
        HlmSelectImports,
        NgClass,
        CdkListboxModule
    ],
    providers: [CdkListbox, CdkListboxModule],
})
export class LabelModalComponent {
    readonly dialogData = inject<EditLabelDialogData>(MAT_DIALOG_DATA);
    readonly dialog = viewChild(HlmDialogComponent);
    readonly selectElement = viewChild(BrnSelectComponent);
    readonly inboxContext = inject(InboxContext);
    readonly dialogService = inject(CustomDialogService);
    private readonly _labelQueries = inject(LabelQueries);
    private readonly _labelMutations = inject(LabelMutations);
    
    readonly shouldBeSubLabel = signal(this.dialogData.label?.isSubLabel() ?? false);
    
    readonly userCustomLabels = computed(() => this._labelQueries.userLabels().filter((label) => label.type !== LabelType.SYSTEM));
    
    readonly selectedParentLabelId = model<string | null>(
        this.dialogData.label?.isSubLabel() ? this.userCustomLabels().find((label) => label.name === this.dialogData.label?.getParentName())!.id : null
    );

    readonly selectedParentLabel = computed(() => this.userCustomLabels().find((label) => label.id === this.selectedParentLabelId()));

    readonly rootLabels = computed(() => this.userCustomLabels().filter((label) => !label.isSubLabel()));

    readonly labelText = signal(this.dialogData.label?.getNameWithoutParent() ?? '');

    readonly availableColors = [
        '#000000', '#434343', '#666666', '#999999', '#cccccc', '#efefef', '#f3f3f3', '#ffffff', '#fb4c2f', '#ffad47', '#fad165', '#16a766', '#43d692', '#4a86e8', '#a479e2', '#f691b3', '#f6c5be', '#ffe6c7', '#fef1d1', '#b9e4d0', '#c6f3de', '#c9daf8', '#e4d7f5', '#fcdee8', '#efa093', '#ffd6a2', '#fce8b3', '#89d3b2', '#a0eac9', '#a4c2f4', '#d0bcf1', '#fbc8d9', '#e66550', '#ffbc6b', '#fcda83', '#44b984', '#68dfa9', '#6d9eeb', '#b694e8', '#f7a7c0', '#cc3a21', '#eaa041', '#f2c960', '#149e60', '#3dc789', '#3c78d8', '#8e63ce', '#e07798', '#ac2b16', '#cf8933', '#d5ae49', '#0b804b', '#2a9c68', '#285bac', '#653e9b', '#b65775', '#822111', '#a46a21', '#aa8831', '#076239', '#1a764d', '#1c4587', '#41236d', '#83334c', '#464646', '#e7e7e7', '#0d3472', '#b6cff5', '#0d3b44', '#98d7e4', '#3d188e', '#e3d7ff', '#711a36', '#fbd3e0', '#8a1c0a', '#f2b2a8', '#7a2e0b', '#ffc8af', '#7a4706', '#ffdeb5', '#594c05', '#fbe983', '#684e07', '#fdedc1', '#0b4f30', '#b3efd3', '#04502e', '#a2dcc1', '#c2c2c2', '#4986e7', '#2da2bb', '#b99aff', '#994a64', '#f691b2', '#ff7537', '#ffad46', '#662e37', '#ebdbde', '#cca6ac', '#094228', '#42d692', '#16a765'
    ];

    readonly backgroundColor = signal(this.dialogData.label?.color.backgroundColor ?? this.availableColors[0]);

    selectedLabel: any;

    selectBackgroundColor(color: string) {
        this.backgroundColor.set(color);
    }

    readonly canSubmit = computed(() => {
        if (this.shouldBeSubLabel()) {
            return !!this.selectedParentLabel() && this.labelText();
        }

        return this.labelText();
    });

    constructor() {
        effect(() => {
            if (this.dialog()?.state() === 'closed') {
                this.dialogService.closeAll();
            }
        });

        setTimeout(() => {

        }, 1000);
    }


    createLabel(): void {
        const newLabel = new Label({
            id: this.dialogData.label?.id ?? undefined,
            name: this.selectedParentLabel() !== null ? `${this.selectedParentLabel()?.name}/${this.labelText()}` : this.labelText(),
            socialId: this.dialogData.label?.socialId ?? undefined,
            color: {
                backgroundColor: this.backgroundColor(),
                textColor: this.getCorrespondingTextColorForContrast(this.backgroundColor()),
            },
            type: LabelType.USER,
            userId: this.inboxContext.currentUserId()!,
        });
        this._labelMutations.createLabelMutation.mutate(newLabel);
        this.dialog()?.close({});
    }

    getCorrespondingTextColorForContrast(backgroundColor: string): string {
        const r = parseInt(backgroundColor.substr(1, 2), 16);
        const g = parseInt(backgroundColor.substr(3, 2), 16);
        const b = parseInt(backgroundColor.substr(5, 2), 16);
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq >= 128 ? '#000000' : '#ffffff';
    }
}
