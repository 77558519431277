import { Types } from 'mongoose';

export type RemoveMethodsFromClass<T, ChildEntities> = Omit<
    {
        [K in keyof T as T[K] extends Function
            ? never
            : K extends keyof ChildEntities
              ? never
              : K]: T[K];
    },
    keyof BaseEntity
> &
    Partial<BaseEntity> &
    ChildEntities;

export interface Timestampeable {
    createdAt: Date;
    updatedAt: Date;
}

export type EntityConstructor<
    Entity,
    ChildEntities = {},
> = RemoveMethodsFromClass<Entity, ChildEntities>;

export interface IBaseEntity {
    id: string;
    createdAt: Date;
    updatedAt: Date;
}

export class BaseEntity implements IBaseEntity {
    readonly id: string;
    readonly createdAt: Date;
    updatedAt: Date;

    constructor(data: Partial<IBaseEntity>) {
        this.id = data.id ?? new Types.ObjectId().toString();
        this.createdAt = data.createdAt ?? new Date();
        this.updatedAt = data.updatedAt ?? new Date();
    }
}
