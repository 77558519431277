import { Route } from '@angular/router';
import { AuthGuard } from '@web/app/modules/auth/guards/auth.guard';
import { LOGGED_OUT_ROUTES } from './core/routes/logged-out/logged-out.routes';
import { GLOBAL_LAYOUT_ROUTER } from './core/layout/global-layout-router.routing';

export const appRoutes: Route[] = [
    {
        path: 'mail',
        children: GLOBAL_LAYOUT_ROUTER,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
    },
    {
        path: '',
        children: LOGGED_OUT_ROUTES,
    },
];
