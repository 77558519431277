/**
 * @packageDocumentation
 * @module api.functional.api.authentication
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Resolved, Primitive } from "typia";
import typia from "typia";

import type {
  LoginDTO,
  AuthSuccessDTO,
} from "@recapp/dto";
import type { ApiResult } from "@recapp/shared-types";
export * as google from "./google";

/**
 * @controller AuthenticationController.login
 * @path POST /api/authentication/login
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function login(
  connection: IConnection,
  input: login.Input,
): Promise<login.Output> {
  return !!connection.simulate
    ? login.simulate(connection, input)
    : PlainFetcher.fetch(
        {
          ...connection,
          headers: {
            ...connection.headers,
            "Content-Type": "application/json",
          },
        },
        {
          ...login.METADATA,
          template: login.METADATA.path,
          path: login.path(),
        },
        input,
      );
}
export namespace login {
  export type Input = Resolved<LoginDTO>;
  export type Output = Primitive<ApiResult<AuthSuccessDTO, never>>;

  export const METADATA = {
    method: "POST",
    path: "/api/authentication/login",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 201,
  } as const;

  export const path = () => "/api/authentication/login";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<AuthSuccessDTO, never>>> =>
    typia.random<Primitive<ApiResult<AuthSuccessDTO, never>>>(g);
  export const simulate = (
    connection: IConnection,
    input: login.Input,
  ): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(),
      contentType: "application/json",
    });
    assert.body(() => typia.assert(input));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
