import { computed, Directive, inject, input } from '@angular/core';
import { BrnAvatarImageDirective } from '@spartan-ng/ui-avatar-brain';
import { hlm } from '@spartan-ng/ui-core';
import { ClassValue } from 'clsx';

@Directive({
    selector: 'img[hlmAvatarImage]',
    standalone: true,
    exportAs: 'avatarImage',
    hostDirectives: [BrnAvatarImageDirective],
    host: {
        '[class]': '_computedClass()',
    },
})
export class HlmAvatarImageDirective {
    canShow = inject(BrnAvatarImageDirective).canShow;

    public readonly userClass = input<ClassValue>('', { alias: 'class' });
    protected _computedClass = computed(() =>
        hlm('aspect-square object-cover h-full w-full', this.userClass()),
    );
}
