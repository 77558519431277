export enum ModuleName {
    AUTHENTICATION = 'AUTHENTICATION',
    UNKNOWN_MODULE = 'UNKNOWN_MODULE',
    PLATFORMS = 'PLATFORMS',
    THREADS = 'THREADS',
    USERS = 'USERS',
    EMAILS = 'EMAILS',
}

export type ErrorProps = {
    statusCode: number;
    message: string;
    errorFromModule: ModuleName;
};

export type ErrorDefinition<ErrName extends string> = Record<
    ErrName,
    ErrorProps
>;
