/**
 * @packageDocumentation
 * @module api.functional.api.authentication.google.callback
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import typia from "typia";
import type { Resolved } from "typia";

/**
 * @controller AuthenticationController.oAuthGoogle
 * @path GET /api/authentication/google/callback
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function oAuthGoogle(
  connection: IConnection,
  code: string,
  state: string,
): Promise<void> {
  return !!connection.simulate
    ? oAuthGoogle.simulate(connection, code, state)
    : PlainFetcher.fetch(connection, {
        ...oAuthGoogle.METADATA,
        template: oAuthGoogle.METADATA.path,
        path: oAuthGoogle.path(code, state),
      });
}
export namespace oAuthGoogle {
  export const METADATA = {
    method: "GET",
    path: "/api/authentication/google/callback",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = (code: string, state: string) => {
    const variables: URLSearchParams = new URLSearchParams();
    for (const [key, value] of Object.entries({
      code,
      state,
    } as any))
      if (undefined === value) continue;
      else if (Array.isArray(value))
        value.forEach((elem: any) => variables.append(key, String(elem)));
      else variables.set(key, String(value));
    const location: string = "/api/authentication/google/callback";
    return 0 === variables.size
      ? location
      : `${location}?${variables.toString()}`;
  };
  export const random = (g?: Partial<typia.IRandomGenerator>): Resolved<void> =>
    typia.random<void>(g);
  export const simulate = (
    connection: IConnection,
    code: string,
    state: string,
  ): void => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(code, state),
      contentType: "application/json",
    });
    assert.query(() => typia.assert(code));
    assert.query(() => typia.assert(state));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
