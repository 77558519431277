export enum SystemLabel {
    INBOX = 'inbox',
    UNREAD = 'unread',
    FOLLOWED = 'followed',
    ARCHIVED = 'archived',
    TRASH = 'trash',
    SPAM = 'spam',
    SENT = 'sent',
    DRAFT = 'draft',
    NOTIFICATION = 'notification',
}
