import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class GlobalErrorInterceptor implements HttpInterceptor {
    constructor() {}

    // Http interceptor to handle errors
    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((err) => {
                if (err.status === 401) {
                    localStorage.removeItem('jwtToken');
                    location.reload();
                } else if (err.status === 403) {
                    //TODO: Do something with the 403 error
                }

                return throwError(() => err);
            })
        );
    }
}
