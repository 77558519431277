<hlm-alert-dialog [closeOnBackdropClick]="true">
  <button id="alert-trigger" brnAlertDialogTrigger></button>
  <hlm-alert-dialog-content class="w-[40vw] bg-white" *brnAlertDialogContent='let ctx'>
      <h3 hlmAlertDialogTitle>{{data.title}}</h3>
      <p hlmAlertDialogDescription>
        {{data.description}}
      </p>
    <div class="flex justify-end w-full items-center gap-x-2 mt-1">
      <button hlmBtn [variant]="'outline'" (click)='data.actions.secondary.onClick()'>{{data.actions.secondary.text}}</button>
      <button hlmBtn [variant]="'default'" (click)='data.actions.primary.onClick()'>{{data.actions.primary.text}}</button>
    </div>
  </hlm-alert-dialog-content>
</hlm-alert-dialog>