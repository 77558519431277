/**
 * @packageDocumentation
 * @module api.functional.api.emails.send
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Resolved, Primitive } from "typia";
import typia from "typia";

import type { EmailDTO } from "@recapp/dto";
import type { ApiResult } from "@recapp/shared-types";
import type { SuccessResult } from "@recapp/shared-types";

/**
 * @controller EmailsController.sendEmail
 * @path POST /api/emails/send
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function sendEmail(
  connection: IConnection,
  emailDto: sendEmail.Input,
): Promise<sendEmail.Output> {
  return !!connection.simulate
    ? sendEmail.simulate(connection, emailDto)
    : PlainFetcher.fetch(
        {
          ...connection,
          headers: {
            ...connection.headers,
            "Content-Type": "application/json",
          },
        },
        {
          ...sendEmail.METADATA,
          template: sendEmail.METADATA.path,
          path: sendEmail.path(),
        },
        emailDto,
      );
}
export namespace sendEmail {
  export type Input = Resolved<EmailDTO>;
  export type Output = Primitive<ApiResult<SuccessResult, never>>;

  export const METADATA = {
    method: "POST",
    path: "/api/emails/send",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 201,
  } as const;

  export const path = () => "/api/emails/send";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
    typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
  export const simulate = (
    connection: IConnection,
    emailDto: sendEmail.Input,
  ): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(),
      contentType: "application/json",
    });
    assert.body(() => typia.assert(emailDto));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
