<div class="flex h-full w-full flex-col items-center justify-center gap-y-4 md:p-3">
    <div class="mb-6 flex items-center gap-x-3">
        <img class="h-16 w-16" src="assets/logos/recapp.svg" alt="Recapp logo" />
        <h1 class="text-4xl font-bold" style="font-family: 'StabliGrotesk'">Recapp</h1>
    </div>
    <div class="flex flex-col items-center gap-y-4 text-center text-4xl font-semibold duration-500 animate-in fade-in slide-in-from-top-10">
        <h1 [innerHTML]="'home.title' | translate"></h1>
    </div>
    <div class="mt-8 flex flex-col items-center gap-y-4 duration-2000 animate-in fade-in slide-in-from-top-10">
        <button class="text-lg" size="lg" hlmBtn>
            <a [href]="formLink()">{{ 'home.cta' | translate }}</a>
        </button>
        <button hlmBtn variant="link" size="lg">
            <a routerLink="/auth">{{ 'home.login' | translate }}</a>
        </button>
    </div>
    <div class="absolute bottom-0 left-[50%] mb-4 translate-x-[-50%]">
        <div class="flex items-center gap-x-5">
            <button class="text-text" hlmBtn variant="link">
                <a routerLink="/terms">{{ 'home.terms' | translate }}</a>
            </button>
            <button class="text-text" hlmBtn variant="link">
                <a routerLink="/privacy">{{ 'home.privacy' | translate }}</a>
            </button>
        </div>
    </div>
</div>
