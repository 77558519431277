/**
 * @packageDocumentation
 * @module api.functional.api.threads
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Primitive, Resolved } from "typia";
import typia from "typia";

import type {
  ThreadDTO,
  TrashThreadsDTO,
} from "@recapp/dto";
import type { ApiResult } from "@recapp/shared-types";
import type { SuccessResult } from "@recapp/shared-types";

export * as read_state from "./read_state";
export * as labels from "./labels";
export * as search from "./search";
/**
 * @controller ThreadsController.getThread
 * @path GET /api/threads/:threadId
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getThread(
  connection: IConnection,
  threadId: string,
): Promise<getThread.Output> {
  return !!connection.simulate
    ? getThread.simulate(connection, threadId)
    : PlainFetcher.fetch(connection, {
        ...getThread.METADATA,
        template: getThread.METADATA.path,
        path: getThread.path(threadId),
      });
}
export namespace getThread {
  export type Output = Primitive<ApiResult<ThreadDTO, never>>;

  export const METADATA = {
    method: "GET",
    path: "/api/threads/:threadId",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = (threadId: string) =>
    `/api/threads/${encodeURIComponent(threadId?.toString() ?? "null")}`;
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<ThreadDTO, never>>> =>
    typia.random<Primitive<ApiResult<ThreadDTO, never>>>(g);
  export const simulate = (
    connection: IConnection,
    threadId: string,
  ): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(threadId),
      contentType: "application/json",
    });
    assert.param("threadId")(() => typia.assert(threadId));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}

/**
 * @controller ThreadsController.trash
 * @path PUT /api/threads/trash
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function trash(
  connection: IConnection,
  { threadsIds }: trash.Input,
): Promise<trash.Output> {
  return !!connection.simulate
    ? trash.simulate(connection, { threadsIds })
    : PlainFetcher.fetch(
        {
          ...connection,
          headers: {
            ...connection.headers,
            "Content-Type": "application/json",
          },
        },
        {
          ...trash.METADATA,
          template: trash.METADATA.path,
          path: trash.path(),
        },
        { threadsIds },
      );
}
export namespace trash {
  export type Input = Resolved<TrashThreadsDTO>;
  export type Output = Primitive<ApiResult<SuccessResult, never>>;

  export const METADATA = {
    method: "PUT",
    path: "/api/threads/trash",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = () => "/api/threads/trash";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
    typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
  export const simulate = (
    connection: IConnection,
    { threadsIds }: trash.Input,
  ): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(),
      contentType: "application/json",
    });
    assert.body(() => typia.assert({ threadsIds }));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}

/**
 * @controller ThreadsController.$delete
 * @path DELETE /api/threads
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function $delete(
  connection: IConnection,
  { threadsIds }: $delete.Input,
): Promise<$delete.Output> {
  return !!connection.simulate
    ? $delete.simulate(connection, { threadsIds })
    : PlainFetcher.fetch(
        {
          ...connection,
          headers: {
            ...connection.headers,
            "Content-Type": "application/json",
          },
        },
        {
          ...$delete.METADATA,
          template: $delete.METADATA.path,
          path: $delete.path(),
        },
        { threadsIds },
      );
}
export namespace $delete {
  export type Input = Resolved<TrashThreadsDTO>;
  export type Output = Primitive<ApiResult<SuccessResult, never>>;

  export const METADATA = {
    method: "DELETE",
    path: "/api/threads",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = () => "/api/threads";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
    typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
  export const simulate = (
    connection: IConnection,
    { threadsIds }: $delete.Input,
  ): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(),
      contentType: "application/json",
    });
    assert.body(() => typia.assert({ threadsIds }));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
