import { AUTH_ERRORS } from './auth';
import { EMAILS_ERRORS } from './emails';
import { PLATFORMS_ERRORS } from './platforms';
import { THREADS_ERRORS } from './threads';
import { ErrorProps, ModuleName } from './types';
import { USERS_ERRORS } from './users';

export * from './auth';

export const allErrorsPossible: Record<string, ErrorProps> = {
    UNKNOWN_ERROR: {
        errorFromModule: ModuleName.UNKNOWN_MODULE,
        message: 'Unknown error',
        statusCode: 500,
    },
    ...AUTH_ERRORS,
    ...PLATFORMS_ERRORS,
    ...THREADS_ERRORS,
    ...USERS_ERRORS,
    ...EMAILS_ERRORS,
};
