import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard {
    private readonly _authService: AuthService = inject(AuthService);
    private readonly _router: Router = inject(Router);

    canActivate(): boolean {
        if (!this._authService.isAuthenticated()) {
            this._router.navigate(['auth/login']);
            return false;
        }

        return true;
    }
}
