export enum EmailState {
    READ = 'READ',
    UNREAD = 'UNREAD',
    ARCHIVED = 'ARCHIVED',
    TRASHED = 'TRASHED',
    DRAFT = 'DRAFT',
}

export enum Importance {
    URGENT = 'urgent',
    IMPORTANT = 'important',
    NORMAL = 'normal',
    SPAM = 'spam',
}

export enum AttachmentType {
    IMAGE = 'image',
    VIDEO = 'video',
    AUDIO = 'audio',
    CALENDAR_EVENT = 'calendar_event',
    DOCUMENT = 'document',
    LINK = 'link',
    PDF = 'pdf',
    ARCHIVE = 'archive',
}

export enum SyncStatus {
    FINISHED = 'FINISHED',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
    NOT_SYNCED = 'NOT_SYNCED',
}
