/**
 * @packageDocumentation
 * @module api.functional.api.emails
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Resolved, Primitive } from "typia";
import typia from "typia";

import type { EmailDTO } from "@recapp/dto";
import type { ApiResult } from "@recapp/shared-types";
import type { SuccessResult } from "@recapp/shared-types";

export * as drafts from "./drafts";
export * as send from "./send";
export * as calendar from "./calendar";
export * as get_cloud_storage_upload_params from "./get_cloud_storage_upload_params";

/**
 * @controller EmailsController.update
 * @path PUT /api/emails
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function update(
  connection: IConnection,
  emailDto: update.Input,
): Promise<update.Output> {
  return !!connection.simulate
    ? update.simulate(connection, emailDto)
    : PlainFetcher.fetch(
        {
          ...connection,
          headers: {
            ...connection.headers,
            "Content-Type": "application/json",
          },
        },
        {
          ...update.METADATA,
          template: update.METADATA.path,
          path: update.path(),
        },
        emailDto,
      );
}
export namespace update {
  export type Input = Resolved<EmailDTO>;
  export type Output = Primitive<ApiResult<SuccessResult, never>>;

  export const METADATA = {
    method: "PUT",
    path: "/api/emails",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = () => "/api/emails";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
    typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
  export const simulate = (
    connection: IConnection,
    emailDto: update.Input,
  ): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(),
      contentType: "application/json",
    });
    assert.body(() => typia.assert(emailDto));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}

/**
 * @controller EmailsController.deleteEmail
 * @path DELETE /api/emails/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function deleteEmail(
  connection: IConnection,
  id: string,
): Promise<deleteEmail.Output> {
  return !!connection.simulate
    ? deleteEmail.simulate(connection, id)
    : PlainFetcher.fetch(connection, {
        ...deleteEmail.METADATA,
        template: deleteEmail.METADATA.path,
        path: deleteEmail.path(id),
      });
}
export namespace deleteEmail {
  export type Output = Primitive<ApiResult<SuccessResult, never>>;

  export const METADATA = {
    method: "DELETE",
    path: "/api/emails/:id",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = (id: string) =>
    `/api/emails/${encodeURIComponent(id?.toString() ?? "null")}`;
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
    typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
  export const simulate = (connection: IConnection, id: string): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(id),
      contentType: "application/json",
    });
    assert.param("id")(() => typia.assert(id));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
