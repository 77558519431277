/**
 * @packageDocumentation
 * @module api.functional.api.threads.read_state
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Resolved, Primitive } from "typia";
import typia from "typia";

import type { UpdateThreadReadStateDTO } from "@recapp/dto";
import type { ApiResult } from "@recapp/shared-types";
import type { SuccessResult } from "@recapp/shared-types";

/**
 * @controller ThreadsController.updateReadState
 * @path PUT /api/threads/read-state
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function updateReadState(
  connection: IConnection,
  { isRead, threadsIds }: updateReadState.Input,
): Promise<updateReadState.Output> {
  return !!connection.simulate
    ? updateReadState.simulate(connection, { isRead, threadsIds })
    : PlainFetcher.fetch(
        {
          ...connection,
          headers: {
            ...connection.headers,
            "Content-Type": "application/json",
          },
        },
        {
          ...updateReadState.METADATA,
          template: updateReadState.METADATA.path,
          path: updateReadState.path(),
        },
        { isRead, threadsIds },
      );
}
export namespace updateReadState {
  export type Input = Resolved<UpdateThreadReadStateDTO>;
  export type Output = Primitive<ApiResult<SuccessResult, never>>;

  export const METADATA = {
    method: "PUT",
    path: "/api/threads/read-state",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = () => "/api/threads/read-state";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
    typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
  export const simulate = (
    connection: IConnection,
    { isRead, threadsIds }: updateReadState.Input,
  ): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(),
      contentType: "application/json",
    });
    assert.body(() => typia.assert({ isRead, threadsIds }));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
