import { inject, Injectable } from '@angular/core';
import { ApiResult, SuccessResult } from '@recapp/shared-types';
import { TrpcService } from '@web/app/core/services/trpc.service';
import { Label } from '../models/label.model';

@Injectable({
    providedIn: 'root',
})
export class LabelsService {
    private readonly _trpcService = inject(TrpcService);

    getUserLabels(): Promise<ApiResult<Label[]>> {
        return this._trpcService.api.labels
            .getUserLabels(this._trpcService.connection)
            .then((res) => new ApiResult(res.data.map((ldto) => Label.fromDto(ldto))));
    }

    createLabel(label: Label): Promise<ApiResult<SuccessResult>> {
        return this._trpcService.api.labels
            .create(this._trpcService.connection, label.toDto())
            .then((res) => new ApiResult(res.data));
    }

    deleteLabel(id: string): Promise<ApiResult<SuccessResult>> {
        return this._trpcService.api.labels
            .$delete(this._trpcService.connection, id)
            .then((res) => new ApiResult(res.data));
    }
}
