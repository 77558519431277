import { createReducer, on } from '@ngrx/store';
import { InboxViewType } from '../types/inbox-tab-filter.enum';
import * as InboxActions from './inbox.actions';

export interface InboxState {
    inboxViewType: InboxViewType;
}

export const initialState: InboxState = {
    inboxViewType: InboxViewType.CARD,
};

const _inboxReducer = createReducer(
    initialState,
    on(InboxActions.setInboxViewType, (state, { inboxViewType }) => ({ ...state, inboxViewType }))
);

export function inboxReducer(state: InboxState | undefined, action: any) {
    return _inboxReducer(state, action);
}
