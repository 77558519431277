import { computed, inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { injectQuery, injectQueryClient } from '@tanstack/angular-query-experimental';
import { UserState, selectSelectedUserId } from '../../users/store/user.reducer';
import { LabelsService } from '../services/labels.service';

@Injectable({
    providedIn: 'root',
})
export class LabelQueries {
    private readonly _store = inject(Store<UserState>);
    private readonly _labelsService = inject(LabelsService);

    readonly queryClient = injectQueryClient();
    readonly currentUserId = this._store.selectSignal(selectSelectedUserId);

    readonly userLabels = computed(() => this.userLabelsQuery.data()?.data ?? []);

    readonly userLabelsQuery = injectQuery(() => ({
        queryKey: [this.currentUserId(), 'labels'],
        queryFn: () => this._labelsService.getUserLabels(),
        enabled: !!this.currentUserId(),
        keepPreviousData: true,
        refetchInterval: 60 * 5 * 1000,
    }));
}
