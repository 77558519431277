import {Component, inject, OnInit, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { provideIcons } from "@ng-icons/core";
import { lucideMail, lucideSearch } from "@ng-icons/lucide";
import { TranslateModule } from "@ngx-translate/core";
import {
  CommandComponent,
  InputDirective,
  ItemDirective,
  ListComponent,
  EmptyDirective} from '@ngxpert/cmdk';
import { HlmIconComponent, HlmInputModule, HlmSpinnerComponent } from "@recapp/ui";
import { InboxQueries } from "@web/app/modules/inbox/contexts/inbox.queries";
import { Email } from "@web/app/modules/inbox/models/email.model";
import { EmailLabelsComponent } from "@web/app/shared/components/email-components/email-labels/email-labels.component";
import { ThreadsService } from "@web/app/shared/components/thread-view-modal/services/threads.service";
import { BehaviorSubject, debounceTime, switchMap, tap } from "rxjs";

@Component({
    selector: 'app-global-search',
    standalone: true,
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss'],
    imports: [
    CommandComponent,
    InputDirective,
    ListComponent,
    ItemDirective,
    EmptyDirective,
    TranslateModule,
    HlmInputModule,
    HlmIconComponent,
    EmailLabelsComponent,
    HlmSpinnerComponent
],
    providers: [
      provideIcons({
        lucideSearch,
        lucideMail
      })
    ]
})
export class GlobalSearchComponent implements OnInit{
    private readonly _router = inject(Router);
    private readonly _route = inject(ActivatedRoute);
    private readonly _dialog = inject(MatDialog);
    private readonly _threadService = inject(ThreadsService);
    
    readonly inboxQueries = inject(InboxQueries);
    private readonly _searchText = new BehaviorSubject('');


    readonly filteredThreads = signal<Email[]>([]);
    readonly isLoading = signal(false);
    
    ngOnInit(): void {
        this.isLoading.set(true);
        this._searchText.pipe(
            tap(() => this.isLoading.set(true)),
            debounceTime(300),
            switchMap((value) => this._threadService.search(value))
        ).subscribe((res) => {
            this.filteredThreads.set(res.data);
            this.isLoading.set(false);
        });
    }

    onSelected(id: string ) {
        this._dialog.closeAll();
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { threadId: id },
            queryParamsHandling: 'merge',
        });
    }

    onSearchChange(value: Event) {
        const target = value.target as HTMLInputElement;
        this._searchText.next(target.value);
    }

}