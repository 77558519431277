import { NgModule } from '@angular/core';
import { HlmInputErrorDirective } from './lib/hlm-input-error.directive';
import { HlmInputDirective } from './lib/hlm-input.directive';
import { HlmInputFormFieldComponent } from './lib/hlm-input-form-field.component';
import { HlmInputPrefixDirective } from './lib/hlm-input-prefix.directive';
import { HlmInputSuffixDirective } from './lib/hlm-input-suffix.directive';

export * from './lib/hlm-input-error.directive';
export * from './lib/hlm-input.directive';
export * from './lib/hlm-input-form-field.component';
export { HlmInputPrefixDirective } from './lib/hlm-input-prefix.directive';
export { HlmInputSuffixDirective } from './lib/hlm-input-suffix.directive';

@NgModule({
    imports: [
        HlmInputDirective,
        HlmInputErrorDirective,
        HlmInputPrefixDirective,
        HlmInputPrefixDirective,
        HlmInputSuffixDirective,
        HlmInputFormFieldComponent,
    ],
    exports: [
        HlmInputDirective,
        HlmInputErrorDirective,
        HlmInputPrefixDirective,
        HlmInputSuffixDirective,
        HlmInputFormFieldComponent,
    ],
})
export class HlmInputModule {}
