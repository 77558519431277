import { Pipe, PipeTransform } from '@angular/core';

export enum Logo {
    RECAPP = 'recapp',
}

@Pipe({
    name: 'logoPathResolver',
    standalone: true,
})
export class LogoPathResolverPipe implements PipeTransform {
    transform(key: string): string {
        return `/assets/logos/${key}.png`;
    }
}

@Pipe({
    name: 'svgLogoPathResolver',
    standalone: true,
})
export class SvgLogoPathResolverPipe implements PipeTransform {
    transform(key: string): string {
        return `/assets/logos/${key}.svg`;
    }
}
