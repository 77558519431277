import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@web/envs/environment';
import { Observable, from } from 'rxjs';
import { TApiResult } from '@recapp/shared-types';
import { AuthSuccessDTO, LoginDTO } from '@recapp/dto';
import { TRPC_CONNECTION, TrpcService } from '@web/app/core/services/trpc.service';
import { LocalStorageKey } from '@web/app/shared/enums/local-storage-key';
import { Store } from '@ngrx/store';
import { State } from '@web/app/core/store/storage-meta';
import { selectSelectedUserId } from '../../users/store/user.reducer';

@Injectable({ providedIn: 'root' })
export class AuthService {
    readonly API_BASE_URL = `${environment.API_URL}/api/authentication`;

    readonly currentUserId = this._store.selectSignal(selectSelectedUserId);

    constructor(
        private readonly _trpcService: TrpcService,
        private readonly _jwtHelper: JwtHelperService,
        private readonly _store: Store<State>
    ) {}

    login(params: LoginDTO): Observable<TApiResult<AuthSuccessDTO>> {
        return from(this._trpcService.api.authentication.login(TRPC_CONNECTION, params));
    }

    isAuthenticated(): boolean {
        const token = localStorage.getItem(`${this.currentUserId()}_${LocalStorageKey.JWT_TOKEN}`);
        // Check whether the token is expired
        return !this._jwtHelper.isTokenExpired(token);
    }

    siginWithGoogle(): Observable<TApiResult<string>> {
        return from(this._trpcService.api.authentication.google.url.getGoogleAuthUrl(TRPC_CONNECTION, environment.BASE_URL));
    }
}
