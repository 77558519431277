@if (loading()) {
    <div class="flex h-full w-full items-center justify-center">
        <hlm-spinner size="lg" />
    </div>
} @else {
    <div class="h-full w-full">
        <div class="logo absolute left-5 top-5 text-3xl font-bold" style="font-family: 'StabliGrotesk'">Recapp</div>

        <div class="grid h-full w-full place-items-center">
            <div class="flex w-[30%] flex-col gap-4">
                <!-- TITLE SECTION -->
                <div class="flex-col gap-2">
                    <div class="mb-4 h-[43px] max-w-min rounded-lg border border-text p-2">
                        <mat-icon class="text-text">key</mat-icon>
                    </div>
                    <h1 class="text-xl font-bold">{{ 'auth.register.create_password_form.title' | translate }}</h1>
                </div>

                <!-- FORM SECTION -->
                <form class="w-full" [formGroup]="createPasswordForm">
                    <div class="flex flex-col">
                        <div class="flex flex-col gap-1">
                            <hlm-input-form-field>
                                <input
                                    class="w-full"
                                    hlmInput
                                    placeholder="{{ 'auth.register.create_password_form.password.placeholder' | translate }}"
                                    formControlName="password"
                                    minlength="PASSWORD_MIN_LENGTH"
                                    [required]="true"
                                    [type]="hidePassword ? 'password' : 'text'" />
                                <button
                                    class="p-0 font-normal text-text"
                                    type="button"
                                    variant="link"
                                    size="sm"
                                    hlmBtn
                                    hlmInputSuffix
                                    (click)="hidePassword = !hidePassword">
                                    <span>
                                        @if (hidePassword) {
                                            {{ 'common.show' | translate }}
                                        } @else {
                                            {{ 'common.hide' | translate }}
                                        }
                                    </span>
                                </button>
                            </hlm-input-form-field>
                        </div>
                        <div>
                            <div class="mt-3 flex items-center gap-x-2">
                                <span class="text-xs text-text">{{
                                    'auth.register.create_password_form.password_strength' | translate
                                }}</span>
                                <div class="strength-gauge h-1 w-[150px] rounded-sm bg-gray-400">
                                    <div
                                        class="strength-gauge__bar h-1 rounded-sm transition-all duration-200 ease-in-out"
                                        [ngStyle]="{
                                            width: passwordStrength() + '%',
                                            backgroundColor: passwordStrength() < 50 ? 'red' : passwordStrength() < 70 ? 'orange' : 'green'
                                        }"></div>
                                </div>
                                <span class="text-xs text-text">
                                    {{
                                        passwordStrength() < 50
                                            ? ('auth.register.create_password_form.weak' | translate)
                                            : passwordStrength() < 70
                                              ? ('auth.register.create_password_form.medium' | translate)
                                              : ('auth.register.create_password_form.strong' | translate)
                                    }}
                                </span>
                            </div>

                            <div class="mt-2 text-xs text-text">
                                <ul class="flex flex-col gap-y-1">
                                    <li class="items center flex gap-x-1">
                                        <hlm-icon
                                            size="xs"
                                            [color]="hasMinLength(password()) ? 'green' : undefined"
                                            [name]="!hasMinLength(password()) ? 'lucideCircle' : 'lucideCheckCircle2'"></hlm-icon>
                                        <span>{{ 'auth.register.create_password_form.min_chars' | translate }}</span>
                                    </li>
                                    <li class="items center flex gap-x-1">
                                        <hlm-icon
                                            size="xs"
                                            [color]="hasUppercase(password()) ? 'green' : undefined"
                                            [name]="!hasUppercase(password()) ? 'lucideCircle' : 'lucideCheckCircle2'"></hlm-icon>
                                        <span>{{ 'auth.register.create_password_form.uppercase' | translate }}</span>
                                    </li>
                                    <li class="items center flex gap-x-1">
                                        <hlm-icon
                                            size="xs"
                                            [color]="hasNumber(password()) ? 'green' : undefined"
                                            [name]="!hasNumber(password()) ? 'lucideCircle' : 'lucideCheckCircle2'"></hlm-icon>
                                        <span>{{ 'auth.register.create_password_form.number' | translate }}</span>
                                    </li>
                                    <li class="items center flex gap-x-1">
                                        <hlm-icon
                                            size="xs"
                                            [color]="hasSpecialChar(password()) ? 'green' : undefined"
                                            [name]="!hasSpecialChar(password()) ? 'lucideCircle' : 'lucideCheckCircle2'"></hlm-icon>
                                        <span>{{ 'auth.register.create_password_form.special_char' | translate }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <button
                            class="mt-4 w-full"
                            type="submit"
                            hlmBtn
                            [disabled]="createPasswordForm.invalid || submittingPassword()"
                            (click)="submit()">
                            @if (submittingPassword()) {
                                <hlm-icon class="mr-2 animate-spin" name="lucideLoader2" size="sm" />
                            }
                            <span>
                                {{ 'common.continue' | translate }}
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
}
