<div class="flex items-center gap-x-2">
    @for (label of userLabels().slice(0, 2); track $index) {
        <div class="flex items-center justify-between rounded px-2 py-1" [ngStyle]="{ backgroundColor: label.getRGBABgColor() }">
            <div class="flex items-center gap-x-1">
                <div
                class="h-3 w-3 rounded-[2px] border-[2px]"
                [style]="{
                    borderColor: label.color.backgroundColor,
                    color: label.color.backgroundColor
                }"></div>
            <span class="text-xs font-light text-black max-w-[80px] truncate">
                {{ label.name }}
            </span>
        </div>

        @if(canDelete()) {
            <div class="cursor-pointer p-1 flex hover:bg-light-bg-darker transition-all rounded-sm ml-1">
                <hlm-icon
                    class="h-3 w-3"
                    [size]="'sm'"
                    name="lucideX"
                    (click)="onDelete.emit(label)">
                </hlm-icon>
            </div>
            }
    </div>
    }
    @if(userLabels().length > 2) {
        <div class="flex items-center gap-x-1 rounded px-2 py-1 border border-light-bg-darker">
            <span class="text-xs font-light text-black max-w-[80px] truncate">
                + {{ userLabels().length - 2 }}
            </span>
        </div>
    }
</div>
