import { ErrorDefinition, ModuleName } from '../types';
const errorFromModule = ModuleName.PLATFORMS;

export enum PlatformError {
    PLATFORM_NOT_FOUND = 'PLATFORM_NOT_FOUND',
}

export const PLATFORMS_ERRORS: ErrorDefinition<PlatformError> = {
    [PlatformError.PLATFORM_NOT_FOUND]: {
        errorFromModule,
        message: 'Platform not found',
        statusCode: 404,
    },
};
