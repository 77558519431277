/**
 * @packageDocumentation
 * @module api.functional.api.inbox.list
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Resolved, Primitive } from "typia";
import typia from "typia";

import type {
  GetInboxPaginatedQueryParams,
  InboxPaginatedResponseDTO,
} from "@recapp/dto";

/**
 * @controller InboxController.getInboxPaginated
 * @path GET /api/inbox/list/:userId
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getInboxPaginated(
  connection: IConnection,
  userId: string,
  query: getInboxPaginated.Query,
): Promise<getInboxPaginated.Output> {
  return !!connection.simulate
    ? getInboxPaginated.simulate(connection, userId, query)
    : PlainFetcher.fetch(connection, {
        ...getInboxPaginated.METADATA,
        template: getInboxPaginated.METADATA.path,
        path: getInboxPaginated.path(userId, query),
      });
}
export namespace getInboxPaginated {
  export type Query = Resolved<GetInboxPaginatedQueryParams>;
  export type Output = Primitive<InboxPaginatedResponseDTO>;

  export const METADATA = {
    method: "GET",
    path: "/api/inbox/list/:userId",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = (userId: string, query: getInboxPaginated.Query) => {
    const variables: URLSearchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(query as any))
      if (undefined === value) continue;
      else if (Array.isArray(value))
        value.forEach((elem: any) => variables.append(key, String(elem)));
      else variables.set(key, String(value));
    const location: string = `/api/inbox/list/${encodeURIComponent(userId?.toString() ?? "null")}`;
    return 0 === variables.size
      ? location
      : `${location}?${variables.toString()}`;
  };
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<InboxPaginatedResponseDTO>> =>
    typia.random<Primitive<InboxPaginatedResponseDTO>>(g);
  export const simulate = (
    connection: IConnection,
    userId: string,
    query: getInboxPaginated.Query,
  ): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(userId, query),
      contentType: "application/json",
    });
    assert.param("userId")(() => typia.assert(userId));
    assert.query(() => typia.assert(query));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
