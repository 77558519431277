import { Injectable } from '@angular/core';
import trpc from '@recapp/api-sdk';
import { tokenGetter } from '@web/app/app.config';
import { environment } from '@web/envs/environment';

export const TRPC_CONNECTION: trpc.IConnection = {
    host: environment.API_URL,
};

@Injectable({
    providedIn: 'root',
})
export class TrpcService {
    constructor() {}

    get api() {
        return trpc.functional.api;
    }

    get connection() {
        const token = tokenGetter();
        return {
            ...TRPC_CONNECTION,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
    }
}
