import { ErrorDefinition, ModuleName } from '../types';
const errorFromModule = ModuleName.AUTHENTICATION;

export enum AuthError {
    GOOGLE_PROFILE_BAD_REQUEST = 'GOOGLE_PROFILE_NOT_FOUND',
    GOOGLE_AUTH_BAD_REQUEST_MISSING_TOKEN = 'GOOGLE_AUTH_BAD_REQUEST_MISSING_TOKEN',
    LOGIN_USER_NOT_FOUND = 'LOGIN_USER_NOT_FOUND',
    LOGIN_INVALID_PASSWORD = 'LOGIN_INVALID_PASSWORD',
}

export const AUTH_ERRORS: ErrorDefinition<AuthError> = {
    [AuthError.GOOGLE_PROFILE_BAD_REQUEST]: {
        errorFromModule,
        message:
            'Failed to get user profile from google, please check your permissions',
        statusCode: 400,
    },
    [AuthError.GOOGLE_AUTH_BAD_REQUEST_MISSING_TOKEN]: {
        errorFromModule,
        message: 'Failed to get token from google',
        statusCode: 400,
    },
    [AuthError.LOGIN_USER_NOT_FOUND]: {
        errorFromModule,
        message: 'User not found',
        statusCode: 404,
    },
    [AuthError.LOGIN_INVALID_PASSWORD]: {
        errorFromModule,
        message: 'Invalid password',
        statusCode: 400,
    },
};
