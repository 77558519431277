import { Injectable, computed, inject, signal } from '@angular/core';
import { Thread } from '../models/thread.model';
import { SelectionModel } from '@angular/cdk/collections';
import { InboxService } from '../services/inbox.service';
import { Store } from '@ngrx/store';
import { SyncStatus, SystemLabel } from '@recapp/shared-types';
import { selectSelectedUserId, selectUser, UserState } from '../../users/store/user.reducer';
import { SyncStatusContext } from '@web/app/core/context/sync-status.context';
import { TranslateService } from '@ngx-translate/core';
import { toast } from 'ngx-sonner';
import { interval, switchMap, tap, takeUntil, from, BehaviorSubject, Subject, delay } from 'rxjs';
import { TrashThreadsDTO, UpdateThreadReadStateDTO } from '@recapp/dto';
import { InboxMutations } from './inbox.mutations';
import { Label } from '../models/label.model';
import * as InboxActions from '../store/inbox.actions';

@Injectable({ providedIn: 'root' })
export class InboxContext {
    private readonly _inboxService = inject(InboxService);
    private readonly _syncStatusContext = inject(SyncStatusContext);
    private readonly _translateService = inject(TranslateService);
    private readonly _inboxMutations = inject(InboxMutations);
    private readonly _store = inject(Store<UserState>);

    readonly pageNumber = signal(0);
    readonly inboxViewType = this._store.selectSignal((state) => state.inbox.inboxViewType);

    readonly isUnreadTabSelected = computed(() => this.currentSelectedLabels().every((l) => l === SystemLabel.UNREAD));

    readonly currentUserId = this._store.selectSignal(selectSelectedUserId);
    readonly currentUser = this._store.selectSignal(selectUser);

    readonly hasFinishedSyncing$ = new Subject();
    readonly inboxSelection = new SelectionModel<Thread>(true, []);
    readonly allLabels = [SystemLabel.INBOX, SystemLabel.UNREAD, SystemLabel.FOLLOWED];
    readonly currentSelectedLabels = signal<string[]>(this.allLabels);
    readonly currentSelectedUserLabel = signal<Label | null>(null);

    selectThread(thread: Thread) {
        this.inboxSelection.toggle(thread);
    }

    toggleAllThreads(threads: Thread[]): void {
        if (this.inboxSelection.selected.length === threads.length) {
            this.inboxSelection.clear();
        } else {
            this.inboxSelection.select(...(threads ?? []));
        }
    }

    selectThreads(threads: Thread[]): void {
        this.inboxSelection.select(...(threads ?? []));
    }

    changeCurrentSelectedLabels(labels: string[]): void {
        this.currentSelectedLabels.set(labels);
        this.inboxSelection.clear();
    }

    changeCurrentSelectedSystemLabel(label: SystemLabel): void {
        const newLabels = [];
        if (label === SystemLabel.INBOX) {
            newLabels.push(...this.allLabels);
        } else {
            newLabels.push(label);
        }

        if (this.currentSelectedUserLabel()) {
            newLabels.push(this.currentSelectedUserLabel()!.name);
        }

        this.changeCurrentSelectedLabels(newLabels);
    }

    changeCurrentSelectedUserLabel(label: Label | null): void {
        this.currentSelectedUserLabel.set(label);
        console.log(
            [...this.currentSelectedLabels().filter((l) => Object.keys(SystemLabel).includes(l.toUpperCase())), label?.name].filter(
                (l) => !!l
            )
        );
        this.changeCurrentSelectedLabels(
            [...this.currentSelectedLabels().filter((l) => Object.keys(SystemLabel).includes(l.toUpperCase())), label?.name].filter(
                (l) => !!l
            ) as string[]
        );
        this.inboxSelection.clear();
    }

    updateEmailReadState({ threadsIds, isRead, userLabels }: UpdateThreadReadStateDTO & { userLabels: Label[] }): void {
        this._inboxMutations.updateThreadReadStateMutation.mutate({
            threadsIds,
            isRead,
            currentSelectedLabels: this.currentSelectedLabels(),
            isUnreadTabSelected: this.isUnreadTabSelected(),
            userLabels,
        });
    }

    trashThreads({ threadsIds }: TrashThreadsDTO): void {
        this._inboxMutations.trashThreadsMutation.mutate({ threadsIds, currentSelectedLabels: this.currentSelectedLabels() });
    }

    deleteThreads({ threadsIds }: TrashThreadsDTO): void {
        this._inboxMutations.deleteThreadsMutation.mutate({ threadsIds, currentSelectedLabels: this.currentSelectedLabels() });
    }

    updateThreadLabels({
        threadIds,
        labelsToAdd,
        labelsToRemove,
    }: {
        threadIds: string[];
        labelsToAdd: Label[];
        labelsToRemove: Label[];
    }): void {
        this._inboxMutations.updateThreadLabelsMutation.mutate({
            threadIds,
            labelsToAdd,
            labelsToRemove,
            currentSelectedLabels: this.currentSelectedLabels(),
        });
    }

    startSync() {
        toast.loading(this._translateService.instant('inbox.sync_start_message'), { duration: Number.POSITIVE_INFINITY });

        this._syncStatusContext.isSyncingMailbox.set(true);
        from(this._inboxService.startInboxSync(this.currentUserId()!))
            .pipe(
                delay(2000),
                switchMap(() =>
                    interval(2000).pipe(
                        switchMap(() => from(this._inboxService.getInboxSyncStatus(this.currentUserId()!))),
                        tap((res) => {
                            this._syncStatusContext.syncProgress.set(res.data.progress);
                            if (res.data.status === SyncStatus.FINISHED) {
                                this._syncStatusContext.isSyncingMailbox.set(false);
                                this.hasFinishedSyncing$.next(true);
                                toast.dismiss();
                                toast.success(this._translateService.instant('inbox.sync_success_message'));
                            }
                            if (res.data.status === SyncStatus.FAILED) {
                                this._syncStatusContext.isSyncingMailbox.set(false);
                                this.hasFinishedSyncing$.next(true);
                                toast.dismiss();
                                toast.error(this._translateService.instant('inbox.sync_failed_message'));
                            }
                        }),
                        takeUntil(this.hasFinishedSyncing$)
                    )
                )
            )
            .subscribe({
                error: () => {
                    toast.dismiss();
                    toast.error(this._translateService.instant('inbox.sync_failed_message'));
                    this._syncStatusContext.isSyncingMailbox.set(false);
                },
            });
    }

    changeViewType(viewType: string) {
        this._store.dispatch({ type: InboxActions.setInboxViewType.type, inboxViewType: viewType });
    }

    clearState(): void {
        this.inboxSelection.clear();
        this.currentSelectedLabels.set([SystemLabel.UNREAD]);
    }
}
