import { computed, Directive, input } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const cardVariants = cva(
    'rounded-lg border border-border bg-card focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-card-foreground shadow-sm',
    {
        variants: {},
        defaultVariants: {},
    },
);
export type CardVariants = VariantProps<typeof cardVariants>;

@Directive({
    selector: '[hlmCard]',
    standalone: true,
    host: {
        '[class]': '_computedClass()',
    },
})
export class HlmCardDirective {
    public readonly userClass = input<ClassValue>('', { alias: 'class' });
    protected _computedClass = computed(() =>
        hlm(cardVariants(), this.userClass()),
    );
}
