/**
 * @packageDocumentation
 * @module api.functional.api.inbox.sync
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Primitive, Resolved } from "typia";
import typia from "typia";

import type { ApiResult } from "@recapp/shared-types";
import type { SuccessResult } from "@recapp/shared-types";

export * as status from "./status";

/**
 * @controller InboxController.syncInbox
 * @path POST /api/inbox/sync/:userId
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function syncInbox(
  connection: IConnection,
  userId: string,
): Promise<syncInbox.Output> {
  return !!connection.simulate
    ? syncInbox.simulate(connection, userId)
    : PlainFetcher.fetch(connection, {
        ...syncInbox.METADATA,
        template: syncInbox.METADATA.path,
        path: syncInbox.path(userId),
      });
}
export namespace syncInbox {
  export type Output = Primitive<ApiResult<SuccessResult, never>>;

  export const METADATA = {
    method: "POST",
    path: "/api/inbox/sync/:userId",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 201,
  } as const;

  export const path = (userId: string) =>
    `/api/inbox/sync/${encodeURIComponent(userId?.toString() ?? "null")}`;
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
    typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
  export const simulate = (connection: IConnection, userId: string): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(userId),
      contentType: "application/json",
    });
    assert.param("userId")(() => typia.assert(userId));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
