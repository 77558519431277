import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../store/storage-meta';
import { selectUser } from '@web/app/modules/users/store/user.reducer';

@Injectable({
    providedIn: 'root',
})
export class UsersContext {
    private readonly _store = inject(Store<State>);
    readonly currentUser = this._store.selectSignal(selectUser);
}
