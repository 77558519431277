<div class="flex flex-col px-4">
    @if (sidenavContext.opened()) {
        <span class="text-sm text-light fade-in-30">Labels</span>
    } @else if (!sidenavContext.opened()) {
        <div class="h-5"></div>
    }
    <div class="flex flex-col px-1 transition-all">
        <button
            class="nav-item mt-3 flex h-7 min-h-[16px] cursor-pointer items-center gap-x-2 rounded-sm px-2 text-light"
            hlmBtn
            (click)="openLabelModal()">
            <hlm-icon name="lucidePlusCircle" size="sm"></hlm-icon>
            @if (sidenavContext.opened()) {
                <span class="truncate text-sm">
                    {{ 'sidebar.labels.create' | translate }}
                </span>
            }
        </button>
        @for (label of userCustomLabels(); track $index) {
            <div class="items-center justify-between mt-2 nav-item flex h-7 min-h-[16px] cursor-pointer transition-all rounded-sm px-2.5 min-w-max" [ngClass]="{
                'is-route-active': isLabelSelected | applyPure: label.name : this.inboxContext.currentSelectedLabels()
            }" (click)="toggleSelectLabel(label)">
                <div
                    class="flex items-center gap-x-2"
                    [ngClass]="{
                        'ml-3': label.isSubLabel() && sidenavContext.opened(),
                    }"
                    >
                    <div
                        class="h-3 w-3 rounded-[2px] border-[2px]"
                        [style]="{
                            borderColor: label.color.backgroundColor,
                            color: label.color.backgroundColor
                        }"></div>
                    @if (sidenavContext.opened()) {
                        <span class="truncate text-sm text-light max-w-[78%]">{{ label.isSubLabel() ? label.getNameWithoutParent() : label.name }}</span>
                    }
                </div>

                <hlm-icon
                    (click)="$event.stopPropagation()"
                    class="cursor-pointer hidden"
                    name="lucideMoreVertical"
                    size="sm"
                    [brnMenuTriggerFor]="menu"></hlm-icon>
            </div>
            <ng-template #menu>
               <hlm-menu class="w-64">
                   <button class="cursor-pointer" hlmMenuItem (click)="openLabelModal(label)">
                       {{'common.edit' | translate}}
                   </button>
                    <button class="cursor-pointer" hlmMenuItem (click)="deleteLabel(label)">
                        {{'common.delete' | translate}}
                    </button>
                </hlm-menu>
            </ng-template>
        }
    </div>
</div>
