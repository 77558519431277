import { computed, Directive, input } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const inputErrorVariants = cva('text-destructive text-sm font-medium', {
    variants: {},
    defaultVariants: {},
});
export type InputErrorVariants = VariantProps<typeof inputErrorVariants>;

@Directive({
    selector: '[hlmInputError]',
    standalone: true,
    host: {
        '[class]': '_computedClass()',
    },
})
export class HlmInputErrorDirective {
    public readonly userClass = input<ClassValue>('', { alias: 'class' });
    protected _computedClass = computed(() =>
        hlm(inputErrorVariants(), this.userClass()),
    );
}
