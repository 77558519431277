import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { lucideX } from '@ng-icons/lucide';
import { HlmIconComponent, provideIcons } from '@recapp/ui';
import { Label, LabelType } from '@web/app/modules/inbox/models/label.model';

@Component({
    selector: 'app-email-labels',
    templateUrl: './email-labels.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgStyle, HlmIconComponent],
    providers: [
        provideIcons({
            lucideX
        })
    ],
})
export class EmailLabelsComponent {
    readonly labels = input.required<Label[]>();
    readonly canDelete = input(false);
    readonly onDelete = output<Label>();

    userLabels = computed(() => this.labels().filter((label) => label.type === LabelType.USER));
}
