@if (section() && sidenavContext.opened()) {
    <span class="text-sm text-light fade-in-30">{{ section() }}</span>
} @else if (section() && !sidenavContext.opened()) {
    <div class="h-6"></div>
}
<a
    class="nav-item justify-between my-1.5 flex h-7 items-center rounded-sm px-3  w-full"
    [ngClass]="{'gap-x-2 !px-2 w-max': !sidenavContext.opened()}"
    routerLinkActive="is-route-active"
    [routerLink]="['/mail', routeName()]">
    <div class="flex items-center gap-x-2">
        <hlm-icon class="text-light" name="{{ icon() }}" size="sm"></hlm-icon>
        @if (sidenavContext.opened()) {
            <span class="appear truncate text-sm text-light">{{ text() }}</span>
        }
    </div>
    <ng-content></ng-content>
</a>
