/**
 * @packageDocumentation
 * @module api.functional.api.threads.search
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Resolved, Primitive } from "typia";
import typia from "typia";

import type {
  EmailDTO,
  SearchThreadsQueryDTO,

} from "@recapp/dto";
import type { ApiResult } from "@recapp/shared-types";

/**
 * @controller ThreadsController.execute
 * @path POST /api/threads/search
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function execute(
  connection: IConnection,
  query: execute.Input,
): Promise<execute.Output> {
  return !!connection.simulate
    ? execute.simulate(connection, query)
    : PlainFetcher.fetch(
        {
          ...connection,
          headers: {
            ...connection.headers,
            "Content-Type": "application/json",
          },
        },
        {
          ...execute.METADATA,
          template: execute.METADATA.path,
          path: execute.path(),
        },
        query,
      );
}
export namespace execute {
  export type Input = Resolved<SearchThreadsQueryDTO>;
  export type Output = Primitive<ApiResult<Array<EmailDTO>, never>>;

  export const METADATA = {
    method: "POST",
    path: "/api/threads/search",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 201,
  } as const;

  export const path = () => "/api/threads/search";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<Array<EmailDTO>, never>>> =>
    typia.random<Primitive<ApiResult<Array<EmailDTO>, never>>>(g);
  export const simulate = (
    connection: IConnection,
    query: execute.Input,
  ): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(),
      contentType: "application/json",
    });
    assert.body(() => typia.assert(query));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
