import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Ex :
 * // component.html
 * {{ 'GMB' | enumTranslate:'platform_key' }}
 */
@Pipe({
    name: 'enumTranslate',
    standalone: true,
})
export class EnumTranslatePipe implements PipeTransform {
    constructor(private readonly _translate: TranslateService) {}

    transform(enumKey: string, enumTranslateKey: string): string {
        return this._translate.instant(`enums.${enumTranslateKey}.${enumKey.toLowerCase()}`);
    }
}
