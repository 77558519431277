<mat-sidenav-container class="h-full w-full !bg-light-bg hide-scrollbar">
    <mat-sidenav
        class="!visible !flex h-full flex-col items-center !overflow-hidden !border-light-bg-darker !bg-light-bg transition-all hide-scrollbar"
        id="sidenav"
        mode="side"
        [style]="{
            width: (sidenavContext.opened() ? OPENED_WIDTH : CLOSED_WIDTH) + 'px'
        }"
        #sidenav>
        <mat-nav-list class="flex h-full w-full bg-light-bg hide-scrollbar">
            <ng-container *ngTemplateOutlet="sidenavItems"></ng-container>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content
        class="h-full w-full transition-all hide-scrollbar"
        [style]="{
            'padding-left': (sidenavContext.opened() ? OPENED_WIDTH : CLOSED_WIDTH) + 'px',
            'margin-left': 0
        }">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #sidenavItems>
    <div class="flex h-full w-full flex-col dark:text-black">
        <ng-container *ngTemplateOutlet="avatarTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="topSection"></ng-container>
        <ng-container *ngTemplateOutlet="mailSection"></ng-container>
        <app-sidenav-labels></app-sidenav-labels>
        <!-- Available soon -->
        <!-- <ng-container *ngTemplateOutlet="tasksSection"></ng-container> -->
        <ng-container *ngTemplateOutlet="newsletterSection"></ng-container>
        <ng-container *ngTemplateOutlet="bottomSection"></ng-container>
    </div>
</ng-template>

<ng-template #avatarTemplate>
    <div class="mt-2 flex items-center gap-x-4 px-4" [brnMenuTriggerFor]="menu">
        <hlm-avatar class="my-2 h-10 w-10 rounded-sm">
            <img class="h-10 w-10 rounded-sm" src="/assets/avatar.png" hlmAvatarImage />
            <img
                class="h-10 w-10 rounded-sm border bg-white p-1"
                hlmAvatarFallback
                [src]="usersContext.currentUser()?.getFullName() ?? 'unknown' | avatar" />
        </hlm-avatar>
        @if (sidenavContext.opened()) {
            <div class="appear-transition flex flex-col">
                <span class="truncate text-base">{{ usersContext.currentUser()?.getFullName() ?? 'unknown' }}</span>
                <span class="max-w-[80%] truncate text-sm text-light">{{ usersContext.currentUser()?.email ?? 'unknown' }}</span>
            </div>
        }
    </div>

    <ng-template #menu>
        <hlm-menu class="ml-4 w-64">
            <hlm-menu-label>{{ 'sidebar.my_account' | translate }}</hlm-menu-label>
            <hlm-menu-separator />
            <hlm-menu-group>
                <button hlmMenuItem>
                    Profile
                    <hlm-menu-shortcut>⇧⌘P</hlm-menu-shortcut>
                </button>
                <hlm-menu-separator />
                @for (user of allOtherUsers(); track user.id) {
                    <button hlmMenuItem (click)="changeUser(user)">
                        <hlm-avatar class="h-8 w-8 rounded-sm">
                            <img class="h-8 w-8 rounded-sm" src="/assets/avatar.png" hlmAvatarImage />
                            <img
                                class="h-8 w-8 rounded-sm border bg-white p-1"
                                hlmAvatarFallback
                                [src]="usersContext.currentUser()?.getFullName() ?? 'unknown' | avatar" />
                        </hlm-avatar>
                        <span class="ml-2 max-w-[80%] truncate">{{ user.email }}</span>
                    </button>
                }
                <button hlmMenuItem (click)="addAccount()">
                    <hlm-icon name="lucidePlusCircle" hlmMenuIcon />
                    <span>{{ 'sidebar.add_account' | translate }}</span>
                </button>

                <hlm-menu-separator />

                <button hlmMenuItem (click)="logout()">
                    <hlm-icon name="lucideLogOut" hlmMenuIcon />
                    <span>{{ 'sidebar.logout' | translate }}</span>
                </button>

                <button hlmMenuItem (click)="logoutAll()">
                    <hlm-icon name="lucideLogOut" hlmMenuIcon />
                    <span>{{ 'sidebar.logout_all' | translate }}</span>
                </button>
            </hlm-menu-group>
        </hlm-menu>
    </ng-template>
</ng-template>

<ng-template #topSection>
    <div class="mt-8 flex flex-col px-4">
        @if (!sidenavContext.opened()) {
            <button class="mb-1.5 bg-white fade-in-30" hlmBtn variant="outline" size="icon">
                <hlm-icon name="lucidePencil" size="sm"></hlm-icon>
            </button>
        } @else {
            <button class="mb-1.5 flex h-10 gap-x-2 overflow-hidden bg-white fade-in-30" hlmBtn variant="outline" size="default">
                <hlm-icon name="lucidePencil" size="sm"></hlm-icon>
                <span class="truncate">{{ 'sidebar.new_message' | translate }}</span>
            </button>
        }
        <app-nav-item icon="lucideSearch" routeName="search" [text]="'common.search' | translate" (click)="openCommandTool()"></app-nav-item>
        <!-- <app-nav-item icon="lucideSettings2" routeName="settings" [text]="'common.settings' | translate"></app-nav-item> -->
    </div>
    <brn-separator class="my-1.5 mb-3" hlmSeparator />
</ng-template>

<ng-template #mailSection>
    <div class="flex flex-col px-4">
        <app-nav-item icon="lucideInbox" section="Mail" routeName="inbox" [text]="'sidebar.mail.inbox' | translate" (click)="selectInbox()">
            <span class="text-xs">
                {{ inboxQueries.getUnreadThreadsCountQuery.data()?.data?.length ?? 0 }}
            </span>
        </app-nav-item>
    </div>
</ng-template>

<ng-template #newsletterSection>
    <div
        class="flex flex-col px-4"
        [style]="{
            marginTop: sidenavContext.opened() ? '20px' : 0
        }">
        <app-nav-item
            icon="lucideInbox"
            section="Newsletter"
            routeName="newsletter/inbox"
            [text]="'sidebar.newsletter.inbox' | translate"></app-nav-item>
        <app-nav-item
            icon="lucideSettings2"
            routeName="newsletter/subscriptions"
            [text]="'sidebar.newsletter.subscriptions' | translate"></app-nav-item>
    </div>
</ng-template>

<ng-template #bottomSection>
    <div class="flex h-full flex-col justify-end">
        <brn-separator class="my-4" hlmSeparator />
        <!-- dark mode toggle -->
        @if (sidenavContext.opened()) {
            <div class="mb-4 flex w-full items-center justify-between gap-x-2 px-4">
                <span class="pl-3 text-sm text-light">Theme</span>
                <hlm-switch [ngClass]="{ moon: isDarkMode() }" [checked]="isDarkMode()" (changed)="toggleDarkMode($event)"> </hlm-switch>
            </div>
        }
        <div class="flex flex-col justify-end px-4">
            <app-nav-item icon="lucideUserPlus" routeName="invite" [text]="'sidebar.bottom.invite' | translate"></app-nav-item>
            <app-nav-item icon="lucideMessageCircle" routeName="contact" [text]="'sidebar.bottom.contact' | translate"> </app-nav-item>
        </div>
    </div>
</ng-template>