import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HlmButtonDirective } from '@recapp/ui';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [HlmButtonDirective, RouterLink, TranslateModule],
})
export class HomeComponent {
    formLink = signal(
        // get current browser language
        navigator.language.toLowerCase().includes('fr')
            ? 'https://airtable.com/appl8CswP7FbLxOke/pagofdp9cQf7tMzZp/form'
            : 'https://airtable.com/appl8CswP7FbLxOke/pagdy5Z7YSSh0qLgP/form'
    );
}
