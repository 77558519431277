/**
 * @packageDocumentation
 * @module api.functional.api.labels
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Primitive, Resolved } from "typia";
import typia from "typia";

import type { LabelDTO } from "@recapp/dto";
import type { ApiResult } from "@recapp/shared-types";
import type { SuccessResult } from "@recapp/shared-types";

/**
 * @controller LabelsController.getUserLabels
 * @path GET /api/labels
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getUserLabels(
  connection: IConnection,
): Promise<getUserLabels.Output> {
  return !!connection.simulate
    ? getUserLabels.simulate(connection)
    : PlainFetcher.fetch(connection, {
        ...getUserLabels.METADATA,
        template: getUserLabels.METADATA.path,
        path: getUserLabels.path(),
      });
}
export namespace getUserLabels {
  export type Output = Primitive<ApiResult<Array<LabelDTO>, never>>;

  export const METADATA = {
    method: "GET",
    path: "/api/labels",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = () => "/api/labels";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<Array<LabelDTO>, never>>> =>
    typia.random<Primitive<ApiResult<Array<LabelDTO>, never>>>(g);
  export const simulate = (connection: IConnection): Output => {
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}

/**
 * @controller LabelsController.create
 * @path POST /api/labels
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function create(
  connection: IConnection,
  label: create.Input,
): Promise<create.Output> {
  return !!connection.simulate
    ? create.simulate(connection, label)
    : PlainFetcher.fetch(
        {
          ...connection,
          headers: {
            ...connection.headers,
            "Content-Type": "application/json",
          },
        },
        {
          ...create.METADATA,
          template: create.METADATA.path,
          path: create.path(),
        },
        label,
      );
}
export namespace create {
  export type Input = Resolved<LabelDTO>;
  export type Output = Primitive<ApiResult<SuccessResult, never>>;

  export const METADATA = {
    method: "POST",
    path: "/api/labels",
    request: {
      type: "application/json",
      encrypted: false,
    },
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 201,
  } as const;

  export const path = () => "/api/labels";
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
    typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
  export const simulate = (
    connection: IConnection,
    label: create.Input,
  ): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(),
      contentType: "application/json",
    });
    assert.body(() => typia.assert(label));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}

/**
 * @controller LabelsController.$delete
 * @path DELETE /api/labels/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function $delete(
  connection: IConnection,
  id: string,
): Promise<$delete.Output> {
  return !!connection.simulate
    ? $delete.simulate(connection, id)
    : PlainFetcher.fetch(connection, {
        ...$delete.METADATA,
        template: $delete.METADATA.path,
        path: $delete.path(id),
      });
}
export namespace $delete {
  export type Output = Primitive<ApiResult<SuccessResult, never>>;

  export const METADATA = {
    method: "DELETE",
    path: "/api/labels/:id",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = (id: string) =>
    `/api/labels/${encodeURIComponent(id?.toString() ?? "null")}`;
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<SuccessResult, never>>> =>
    typia.random<Primitive<ApiResult<SuccessResult, never>>>(g);
  export const simulate = (connection: IConnection, id: string): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(id),
      contentType: "application/json",
    });
    assert.param("id")(() => typia.assert(id));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
