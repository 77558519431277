import { BaseEntity, EntityConstructor, Importance, PlatformKey, SystemLabel } from '@recapp/shared-types';
import { Email, EmailAuthor } from './email.model';
import { ActionItem, ThreadDTO } from '@recapp/dto';
import { Label } from './label.model';
import { Avatar } from '@web/app/shared/components/avatar/avatar.component';
import { uniqBy } from 'lodash-es';
import { Attachment } from './attachment.model';

type ThreadProps = EntityConstructor<Thread>;

export class Thread extends BaseEntity {
    socialId?: string;
    emails: Email[];
    platformKey: PlatformKey;
    userId: string;
    lastEmailDate: Date;
    boardColumnId?: string;
    summary?: string;
    actionItems?: ActionItem[];
    labelIds?: string[];
    labels?: Label[];

    constructor(props: ThreadProps) {
        super(props);
        this.emails = props.emails.map((email) => new Email(email));
        this.socialId = props.socialId;
        this.platformKey = props.platformKey;
        this.userId = props.userId;
        this.boardColumnId = props.boardColumnId;
        this.lastEmailDate = new Date(props.lastEmailDate);
        this.summary = props.summary;
        this.actionItems = props.actionItems;
        this.labels = props.labels ?? [];
        this.labelIds = props.labels?.map((label) => label.id) ?? [];
    }

    static fromDto(dto: ThreadDTO): Thread {
        return new Thread({
            id: dto.id,
            emails: dto.emails.map((email) => Email.fromDto(email)),
            socialId: dto.socialId,
            platformKey: dto.platformKey,
            userId: dto.userId,
            lastEmailDate: new Date(dto.lastEmailDate),
            summary: dto.summary,
            actionItems: dto.actionItems,
            boardColumnId: dto.boardColumnId,
            labelIds: dto.labelIds,
            createdAt: new Date(dto.createdAt),
            updatedAt: new Date(dto.updatedAt),
            labels: dto.labels?.map((label) => Label.fromDto(label)) ?? [],
        });
    }

    getLastEmail(): Email {
        return this.emails[this.emails.length - 1];
    }

    getDestinationEmails(currentUserEmail: string): string[] {
        return Array.from(
            new Set(
                this.emails.flatMap((email) =>
                    email.destination.map((destination) => (destination.emailAdress === currentUserEmail ? 'You' : destination.emailAdress))
                )
            )
        );
    }

    getLastEmailExceptDrafts(): Email | null {
        return this.emails.filter((email) => !email.isDraft()).pop()!;
    }

    getDestinations({ excludedEmails }: { excludedEmails?: string[] } = {}): EmailAuthor[] {
        return Array.from(
            new Set(
                this.emails
                    .filter((email) => !excludedEmails?.includes(email.author.emailAdress) && email.destination.length > 0)
                    .flatMap((email) => email.destination)
            )
        ).sort((a, b) => a.name.localeCompare(b.name));
    }

    getCcEmails(currentUserEmail: string): string[] {
        return Array.from(
            new Set(
                this.emails.flatMap((email) => email.cc?.map((cc) => (cc.emailAdress === currentUserEmail ? 'You' : cc.emailAdress)) ?? [])
            )
        );
    }

    getCc({ excludedEmails }: { excludedEmails?: string[] }): EmailAuthor[] | undefined {
        if (!this.emails[0].cc) {
            return undefined;
        }

        return Array.from(
            new Set(
                this.emails
                    .filter((email) => !excludedEmails?.includes(email.author.emailAdress) && email.cc!.length > 0)
                    .flatMap((email) => email.cc)
                    .filter((cc): cc is EmailAuthor => cc !== undefined)
            )
        ).sort((a, b) => a.name.localeCompare(b.name));
    }

    getCcAvatars(): Avatar[] {
        return this.emails.flatMap((email) => email.cc ?? []).map((cc) => ({ fallback: cc.name, url: cc.avatarUrl }));
    }

    getSubject(): string {
        return this.emails[0].subject;
    }

    getImportance(): Importance {
        return this.emails[0].importance;
    }

    getAllLabels(): Label[] {
        return this.labels ?? uniqBy(
            this.emails.flatMap((email) => email.labels),
            'name'
        );
    }

    isTrashed(): boolean {
        return this.getAllLabels().some((label) => label.name === SystemLabel.TRASH);
    }

    isUnread(): boolean {
        return this.getAllLabels().some((label) => label.name === SystemLabel.UNREAD);
    }

    isArchived(): boolean {
        return this.getAllLabels().some((label) => label.name === SystemLabel.ARCHIVED);
    }

    getDraftEmail(): Email | undefined {
        return this.emails.find((email) => email.isDraft());
    }

    markAsUnread(unreadLabel: Label): Thread {
        const emails = this.emails.map((email) => {
            const newLabels = [...email.labels, unreadLabel];
            return new Email({ ...email, labels: newLabels });
        });

        const labels = [...this.getAllLabels(), unreadLabel];

        return new Thread({ ...this, emails, labels });
    }

    markAsRead(): Thread {
        const emails = this.emails.map((email) => {
            const newLabels = email.labels.filter((label) => label.name !== SystemLabel.UNREAD);
            return new Email({ ...email, labels: newLabels });
        });

        const labels = this.getAllLabels().filter((label) => label.name !== SystemLabel.UNREAD);

        return new Thread({ ...this, emails, labels });
    }

    getReversedEmails(): Email[] {
        return this.emails.filter((email) => !email.isDraft()).reverse();
    }

    addEmail(email: Email): Thread {
        return new Thread({ ...this, emails: [...this.emails, email] });
    }

    copyWith(data: Partial<Thread>): Thread {
        return new Thread({ ...this, ...data });
    }

    removeDraftEmail(): Thread {
        return new Thread({ ...this, emails: this.emails.filter((email) => !email.isDraft()) });
    }

    removeLabel(label: Label): Thread {
        return new Thread({ ...this, labels: this.labels?.filter((l) => l.id !== label.id), emails: this.emails.map((email) => email.removeLabel(label)) });
    }

    addlabel(label: Label): Thread {
        return new Thread({ ...this, labels: [...(this.labels ?? []), label], emails: this.emails.map((email) => email.addLabel(label)) });
    }

    getParticipants({ excludedEmails }: { excludedEmails?: string[] }): EmailAuthor[] {
        // array of participants (authors) sorted by last email date
        return uniqBy(
            this.emails
                .filter((email) => !excludedEmails?.includes(email.author.emailAdress))
                .map((email) => email.author)
                .reverse(),
            'emailAdress'
        );
    }

    getAllAttachments(): Attachment[] {
        return this.emails.flatMap((email) => email.attachments);
    }

    getReferences(): string {
        return this.emails
            .filter((email) => !!email.messageId)
            .map((email) => email.messageId!)
            .join(' ');
    }

    getLastReceivedEmail(): Email | undefined {
        return this.emails.filter((email) => !email.isFromMe()).pop();
    }

    getThreadAggregatedEmailsInformation(): {
        subject: string;
        author: EmailAuthor;
        destination: EmailAuthor[];
        cc?: EmailAuthor[];
    } {
        return {
            subject: this.emails[0].subject,
            author: this.emails[0].author,
            destination: this.emails[0].destination,
            cc: this.emails[0].cc,
        };
    }
}
