/**
 * @packageDocumentation
 * @module api.functional.api.users
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { NestiaSimulator } from "@nestia/fetcher/lib/NestiaSimulator";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Primitive, Resolved } from "typia";
import typia from "typia";

import type { UserDTO } from "@recapp/dto";
import type { ApiResult } from "@recapp/shared-types";

export * as update_password from "./update_password";

/**
 * @controller UsersController.getUser
 * @path GET /api/users/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getUser(
  connection: IConnection,
  id: string,
): Promise<getUser.Output> {
  return !!connection.simulate
    ? getUser.simulate(connection, id)
    : PlainFetcher.fetch(connection, {
        ...getUser.METADATA,
        template: getUser.METADATA.path,
        path: getUser.path(id),
      });
}
export namespace getUser {
  export type Output = Primitive<ApiResult<UserDTO, never>>;

  export const METADATA = {
    method: "GET",
    path: "/api/users/:id",
    request: null,
    response: {
      type: "application/json",
      encrypted: false,
    },
    status: 200,
  } as const;

  export const path = (id: string) =>
    `/api/users/${encodeURIComponent(id?.toString() ?? "null")}`;
  export const random = (
    g?: Partial<typia.IRandomGenerator>,
  ): Resolved<Primitive<ApiResult<UserDTO, never>>> =>
    typia.random<Primitive<ApiResult<UserDTO, never>>>(g);
  export const simulate = (connection: IConnection, id: string): Output => {
    const assert = NestiaSimulator.assert({
      method: METADATA.method,
      host: connection.host,
      path: path(id),
      contentType: "application/json",
    });
    assert.param("id")(() => typia.assert(id));
    return random(
      "object" === typeof connection.simulate && null !== connection.simulate
        ? connection.simulate
        : undefined,
    );
  };
}
